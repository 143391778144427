@import './fonts.scss';

//colors
$colorWhite: #ffffff;
$colorLight: #D9D0CD; // цвет светлых фонов и текста в Header
$colorText: #D2BDB4; // цвет текса на тёмном фоне
$colorGrey: #5F5B5A; // цвет тёмных фонов и текста на светлом фоне
$colorDark: #4C4948; // цвет Header


//sizes
$sizeContainerLarge: 1920px;
$sizeContainer: 1372px;
$sizeExtraLarge: 1200px;
$sizeLarge: 950px;
$sizeMedium: 650px;
$sizeSmall: 576px;
