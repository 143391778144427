// Подключение шрифтов
@font-face {
  font-family: 'Cormorant';
  src: url('../fonts/Cormorant-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant';
  src: url('../fonts/Cormorant-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant';
  src: url('../fonts/Cormorant-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Great Vibes';
  src: url('../fonts/GreatVibes-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Montserrat-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

// @mixin font-face-montserrat($font-size, $font-weight, $line-height) {
//     font-family: 'Montserrat', sans-serif;
//     font-size: $font-size;
//     font-weight: $font-weight;
//     line-height: $line-height;
// }

// @mixin font-face-playfair-display($font-size, $font-weight, $line-height) {
//     font-family: 'Playfair Display';
//     font-size: $font-size;
//     font-weight: $font-weight;
//     line-height: $line-height;
// }

@mixin font-face-cormorant($font-size, $font-weight, $line-height) {
  font-family: 'Cormorant';
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin font-face-great-vibes($font-size, $font-weight, $line-height) {
  font-family: 'Great Vibes';
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin font-face-helvetica($font-size, $font-weight, $line-height) {
  font-family: 'Helvetica';
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin fontH1() {
  @include font-face-cormorant(64px, 600, 150%);
  letter-spacing: 0.03em;
}

@mixin fontH2() {
  @include font-face-cormorant(48px, 600, 133%);
  letter-spacing: 0.03em;
}

@mixin fontH3() {
  @include font-face-great-vibes(48px, 400, 133%);
  letter-spacing: 0.05em;
}

@mixin fontH4() {
  @include font-face-cormorant(40px, 700, 120%);
  letter-spacing: 0.03em;
}

@mixin fontH5() {
  @include font-face-cormorant(36px, 700, 122%);
  letter-spacing: 0.03em;
}

@mixin fontH6() {
  @include font-face-great-vibes(36px, 400, 122%);
  letter-spacing: 0.02em;
}

@mixin fontH7() {
  @include font-face-cormorant(32px, 500, 125%);
  letter-spacing: 0.03em;
}

@mixin body1() {
  @include font-face-helvetica(28px, 400, 143%);
  letter-spacing: 0.03em;
}

@mixin body2() {
  @include font-face-helvetica(24px, 400, 133%);
  letter-spacing: 0.03em;
}

@mixin subtitle_1() {
  @include font-face-helvetica(20px, 300, 140%);
  letter-spacing: 0.03em;
}

@mixin subtitle_2() {
  @include font-face-helvetica(20px, 700, 140%);
  // letter-spacing: 0.03em;
}

@mixin subtitle_3() {
  @include font-face-helvetica(20px, 700, 140%);
  // letter-spacing: 0.03em;
}

@mixin subtitle_4() {
  @include font-face-helvetica(16px, 300, 150%);
  letter-spacing: 0.03em;
}

@mixin caption() {
  @include font-face-great-vibes(24px, 400, 125%);
  letter-spacing: 0.02em;
}

// @mixin fontPrice() {
// 	@include font-face-montserrat(28px, 500, 130%);
// 	letter-spacing: 2%;
// }

// @mixin fontText() {
// 	@include font-face-montserrat(16px, 400, 130%);
// 	letter-spacing: 0%;
// }

// @mixin fontTextMenu() {
// 	@include font-face-montserrat(14px, 400, 130%);
// 	letter-spacing: 0%;
// }

// @mixin fontDescription() {
// 	@include font-face-montserrat(20px, 400, 120%);
// 	letter-spacing: -0.02em;
// }

// @mixin fontInput() {
// 	@include font-face-montserrat(20px, 300, 130%);
// 	letter-spacing: -0.03em;
// }
