@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.couples {
  background-color: $colorGrey;
  font-size: 0;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 52px 0;
}

.couples img {
  max-width: 100%;
  height: auto;
}

.couples h3 {
  @include fontH2;
  color: $colorText;
  max-width: 240px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 141px;
}

.couples h3 .right {
  justify-self: flex-end;
}

.couples_images {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 104px;
  padding: 0 120px;
}

.couples_action {
  text-align: center;
}

.couples_action button {
  @include subtitle_2;
  padding: 16px 319px;
  box-sizing: border-box;
  background: rgba($colorText, 0.3);
  border: 3px solid $colorText;
  color: $colorWhite;
  cursor: pointer;
  margin-bottom: 62px;
}

@media screen and (max-width: 1620px) {
  .container {
    padding: 32px 0;
  }
  .couples h3 {
    font-size: 44px;
    max-width: 283px;
    margin-bottom: 68px;
  }
}

@media screen and (max-width: 1310px) {
  .couples h3 {
    font-size: 36px;
    max-width: 175px;
  }
}

@media screen and (max-width: 1110px) {
  .couples h3 {
    font-size: 32px;
    max-width: 155px;
  }
  .couples_images {
    margin-bottom: 45px;
    padding: 0 80px;
  }
  .couples_image {
    content: url('../../../assets/images/Couples/couples1024.png');
  }
  .couples_action button {
    padding: 16px 224px;
  }
}

@media screen and (max-width: 820px) {
  .couples h3 {
    font-size: 28px;
    max-width: 125px;
  }
  .couples_images {
    margin-bottom: 45px;
    padding: 0 47px;
  }
  .couples_action button {
    font-size: 16px;
    padding: 16px 224px;
  }
}

@media screen and (max-width: 670px) {
  .couples_images {
    padding: 0 30px;
  }
  .couples_action button {
    font-size: 16px;
    padding: 16px 150px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 530px) {
  .couples h3 {
    font-size: 20px;
    max-width: 95px;
    margin-bottom: 38px;
  }
  .couples_images {
    padding: 0 16px;
  }
  .couples_image {
    content: url('../../../assets/images/Couples/couples450.png');
  }
  .couples_action button {
    font-size: 16px;
    padding: 16px 44px;
  }
}