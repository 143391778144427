@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.adv {
  padding: 257px 0 268px 0;
  background-color: $colorLight;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.adv img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.adv h2 {
  @include fontH2;
  color: $colorGrey;
  max-width: 490px;
  margin: 0 auto;
  margin-top: 30px;
  display: grid;
  grid-template-rows: auto auto;
}

.adv h2 .right {
  justify-self: flex-end;
}

.hand {
  animation: advantages-hand 4s infinite
  }
  @keyframes advantages-hand {
  0% { transform: translateX(0) scale(3); opacity: 0 }
  33% { transform: translateX(0) scale(1); opacity: 1 }
  66% { transform: translateX(20px) scale(1); opacity: 1 }
  100% { transform: translateX(0) scale(3); opacity: 0 }
  }

.mix {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  padding-right: 10%;
  gap: 15px;
  margin-bottom: 170px;
}

.adv__images {
  padding: 0 50px;
  max-width: 1670px;
  margin: 0 auto;
}

.adv__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

// .adv__image {
//   // min-width: 556px;
// }

.image_double {
  position: relative;
}

.adv__img1 {
  transform: translate(20px, 183px) rotate(-6deg);
}

.adv__img2 {
  transform: translate(-10px, 130px) rotate(3.5deg);
}

.adv__img3 {
  transform: translate(0, 210px) rotate(-2deg);
}

.adv__img4 {
  transform: translateY(-160px) rotate(4deg);
}

.adv__img5 {
  transform: translate(-100px, -60px) rotate(-4deg);
  position: relative;
  z-index: 2;
}

.adv__img6 {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(240px, -130px) rotate(4deg);
  z-index: 1;
}

.adv__img7 {
  transform: translateY(-115px) rotate(-3.5deg);
  position: relative;
}

.adv__img1:hover,
.adv__img2:hover,
.adv__img3:hover,
.adv__img4:hover,
.adv__img5:hover,
.adv__img6:hover,
.adv__img7:hover {
  transform: scale(1.1) rotate(0deg);
  // position: relative;
  transition: 1s;
  z-index: 5;
}

// .adv__img6:hover {
//   transform: scale(1.3) rotate(0deg);
//   transition: 1s;
//   z-index: 5;
// }

@media screen and (max-width: 1280px){

  .back {
    padding-left: 110px;
  }
  .adv h2 {
    font-size: 36px;
    max-width: 367px;
  }
  .adv__row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 30px;
  }
  .adv__img1 {
    transform: translate(40px, -100px) rotate(-6deg);
  }
  .adv__img2 {
    transform: translate(-35px, -85px) rotate(3.5deg);
  }
  .adv__img3 {
    transform: translate(35px, -200px) rotate(-2deg);
  }
  .adv__img4 {
    transform: translateY(-30%) rotate(4deg);
  }
  .adv__img5 {
    transform: translate(7%, -45%) rotate(-4deg);
    z-index: 1;
  }
  .adv__img6 {
    position: relative;
    transform: translate(42%, -75%) rotate(4deg);
    z-index: 1;
  }
  .adv__img7 {
    transform: translateY(-40%) rotate(-3.5deg);
  }
  .adv {
    padding: 213px 0 0 0;
  }
  .adv__img1:hover {
    transform: translate(40px, -100px) scale(1.1);
  }
  .adv__img2:hover {
    transform: translate(-35px, -85px) scale(1.1);
  }
  .adv__img3:hover {
    transform: translate(35px, -200px) scale(1.1);
  }
  .adv__img4:hover {
    transform: translateY(-30%) scale(1.1);
  }
  .adv__img5:hover {
    transform: translate(7%, -45%) scale(1.1);
  }
  .adv__img6:hover {
    transform: translate(42%, -75%) scale(1.1);
  }
  .adv__img7:hover {
    transform: translateY(-40%) scale(1.1);
  }
}

@media screen and (max-width: 1020px){
  .mix {
    margin-top: 50px;
    padding-right: 30px;
    }
  .mix img {
    max-width: 103px;  
  }
  .adv h2 {
    font-size: 32px;
    max-width: 325px;
  }
  .back {
    padding-left: 70px;
  }
  .adv {
    padding: 196px 0 0 0;
  }
  .hand {
    animation: advantages-hand 4s infinite
    }
    @keyframes advantages-hand {
    0% { transform: translateX(0) scale(1.5); opacity: 0 }
    33% { transform: translateX(0) scale(0.5); opacity: 1 }
    66% { transform: translateX(0) scale(0.5); opacity: 1 }
    100% { transform: translateX(0) scale(1.5); opacity: 0 }
    }
}

@media screen and (max-width: 768px){
  // .back {
  //   padding-left: 25px;
  // }
  .adv h2 {
    font-size: 36px;
    max-width: 367px;
  }
  .adv__row {
    display: grid;
    grid-template-columns: (1fr);
    text-align: center;
    padding: 0;
    position: relative;
  }
  .adv__img1 {
    transform: translate(0, 50px) rotate(-6deg);
  }
  .adv__img2 {
    transform: translate(0, -40%) rotate(3.5deg);
  }
  .adv__img3 {
    transform: translate(0, -90%) rotate(-2deg);
  }
  .adv__img4 {
    position: absolute;
    left: 30px;
    bottom: 700px;
    transform: translateY(0) rotate(4deg);
    z-index: 1;
  }
  .adv__img5 {
    position: absolute;
    left: 20px;
    bottom: 200px;
    transform: translate(0, 0) rotate(-4deg);
    z-index: 0;
  }
  .adv__img6 {
    position: absolute;
    transform: translate(0, -180px) rotate(4deg);
    z-index: 1;
  }
  .adv__img7 {
    transform: translateY(180px) rotate(-3.5deg);
    z-index: 2;
  }
  .adv__img1:hover {
    transform: translate(0, 50px) scale(1.1);
  }
  .adv__img2:hover {
    transform: translate(0, -40%) scale(1.1);
  }
  .adv__img3:hover {
    transform: translate(0, -90%) scale(1.1);
  }
  .adv__img4:hover {
    transform: translateY(0) scale(1.1);
  }
  .adv__img5:hover {
    transform: translate(0, 0) scale(1.1);
  }
  .adv__img6:hover {
    transform: translate(0, -180px) scale(1.1);
  }
  .adv__img7:hover {
    transform: translateY(180px) scale(1.1);
  }
  .adv {
    padding: 157px 0 250px 0;
  }
  .adv__images {
    padding: 0 75px;
  }
  .mix {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 485px){
  .back {
    padding-left: 20px;
    width: 85px;
  }
  .adv h2 {
    font-size: 20px;
    max-width: 215px;
  }
  .adv__img6 {
    position: absolute;
    transform: translate(0, -480px) rotate(4deg);
    z-index: 1;
  }
  .adv__img7 {
    transform: translateY(-200px) rotate(-3.5deg);
    z-index: 2;
  }
  .adv__img6:hover {
    transform: translate(0, -480px) scale(1.1);
  }
  .adv__img7:hover {
    transform: translateY(-200px) scale(1.1);
  }
  .adv {
    padding: 97px 0 0 0;
  }
  .mix {
    margin-top: 20px;
    padding-right: 40px;
    }
    .hand {
      animation: advantages-hand 4s infinite
      }
      @keyframes advantages-hand {
      0% { transform: translateX(0) scale(1); opacity: 0 }
      33% { transform: translateX(0) scale(0.5); opacity: 1 }
      66% { transform: translateX(0) scale(0.5); opacity: 1 }
      100% { transform: translateX(0) scale(1); opacity: 0 }
      }
}