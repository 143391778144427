@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.services {
  padding: 257px 0 200px 0;
  background-color: $colorGrey;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.services img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.services h2 {
  @include fontH2;
  color: $colorText;
  max-width: 702px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 84px;
  display: grid;
  grid-template-rows: auto auto;
}

.services h2 .right {
  justify-self: flex-end;
}

.services__title {
  text-align: center;
  margin: 44px 0 92px 0;
  padding: 0 15px;
}

.services__hand {
  display: flex;
  justify-content: end;
  padding-right: 170px;
  margin-bottom: 150px;
}

.hand {
  animation: advantages-hand 4s infinite;
}
@keyframes advantages-hand {
  0% {
    transform: translateX(0) scale(3);
    opacity: 0;
  }
  33% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  66% {
    transform: translateX(-50px) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(0) scale(3);
    opacity: 0;
  }
}

.services__images {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.services__img1 {
  transform: translate(80px, -58px) rotate(-9deg);
}

.services__img2 {
  position: relative;
  z-index: 1;
}
.services__img3 {
  transform: translate(-47px, -33px) rotate(11deg);
  z-index: 2;
  position: relative;
}

.services__image:hover {
  transform: scale(1.1);
  transition: 1s;
  z-index: 5;
}

@media screen and (max-width: 1690px) {
  .services h2 {
    font-size: 44px;
    max-width: 643px;
    margin-top: 84px;
    margin-bottom: 46px;
  }
  .services__hand {
    padding-right: 100px;
    margin-bottom: 98px;
  }
  .services__images {
    padding: 0 92px 0 65px;
  }
  .services__img1 {
    transform: translate(70px, 70px) rotate(-9deg);
  }
  .services__img3 {
    transform: translate(-75px, 70px) rotate(11deg);
  }
  .services {
    padding: 257px 0 340px 0;
  }
}

@media screen and (max-width: 1320px) {
  .services h2 {
    font-size: 36px;
    max-width: 527px;
    margin-top: 84px;
    margin-bottom: 14px;
  }
  .services__images {
    padding: 0 30px;
  }
  // .back {
  //   width: 111px;
  // }
}

@media screen and (max-width: 996px) {
  .services {
    padding: 157px 0 185px 0;
  }
  .services h2 {
    font-size: 28px;
    max-width: 410px;
    margin-top: 39px;
    margin-bottom: 98px;
  }
  .services__images {
    padding: 0;
  }
  .back {
    padding-left: 80px;
    width: 100px;
  }
  .services__hand {
    display: none;
  }
  .services__img1 {
    transform: translate(70px, 70px) rotate(-9deg);
  }
  .services__img2 {
    z-index: 2;
    transform: translateY(-40px);
  }
  .services__img3 {
    transform: translate(-75px, 70px) rotate(11deg);
    z-index: 1;
  }
}

@media screen and (max-width: 650px) {
  .services {
    padding: 157px 0 0 0;
  }
  .services h2 {
    font-size: 36px;
    max-width: 534px;
    margin-top: 36px;
    margin-bottom: 95px;
    padding: 0 15px;
  }
  .services__images {
    flex-wrap: wrap;
    padding: 0 70px;
  }
  .services__hand {
    display: none;
  }
  .services__img1 {
    transform: translate(0, 0) rotate(-9deg);
    position: relative;
    z-index: 2;
  }
  .services__img2 {
    z-index: 1;
    transform: translate(0, -100px) rotate(9deg);
  }
  .services__img3 {
    transform: translate(0, -200px) rotate(-9deg);
    z-index: 0;
  }
}

@media screen and (max-width: 480px) {
  .services {
    padding: 100px 0 0 0;
  }
  .services h2 {
    font-size: 20px;
    max-width: 293px;
    margin-bottom: 65px;
  }
  .services__images {
    padding: 0 50px;
  }
  .back {
    padding-left: 20px;
    width: 84px;
  }
  .services__img1 {
    transform: translate(0, 0) rotate(-9deg);
  }
  .services__img2 {
    transform: translate(0, -50px) rotate(9deg);
  }
  .services__img3 {
    transform: translate(0, -100px) rotate(-9deg);
  }
}
