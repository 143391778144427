@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

.container {
  background-color: $colorLight;
}

.back {
  margin-top: -72px;
  padding-left: 120px;
}

.portfolio {
  padding: 329px 0 303px 0;
  max-width: 1920px;
  margin: 0 auto;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; // Изменено с center на flex-start
  gap: 16px;
  padding: 0 20px;
  max-width: 1683px; // Добавлено для ограничения ширины
  margin: 0 auto;
}

.portfolio h2 {
  @include fontH2;
  color: $colorGrey;
  max-width: 616px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto auto;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 100px;
}

@media screen and (max-width: 1720px) {
  .portfolio h2 {
    font-size: 44px;
  }
  .cards {
    max-width: 1456px; // Максимальная ширина контейнера с 4 фото
  }
}

@media screen and (max-width: 1475px) {
  .portfolio h2 {
    font-size: 36px;
    // margin-top: 84px;
    margin-bottom: 52px;
  }
  .back {
    width: 111px;
  }
  .cards {
    max-width: 1073px; // Максимальная ширина контейнера с 3 фото
  }
}

@media screen and (max-width: 1112px) {
  .portfolio {
    padding: 329px 0 185px 0;
  }
  .portfolio h2 {
    font-size: 28px;
    margin-top: 39px;
    margin-bottom: 70px;
  }
  .back {
    padding-left: 120px;
    // width: 100px;
  }
  .cards {
    max-width: 710px; // Максимальная ширина контейнера с 2 фото
  }
}

@media screen and (max-width: 780px) {
  .portfolio {
    padding: 229px 0 125px 0;
  }
  .portfolio h2 {
    margin-top: 36px;
    margin-bottom: 41px;
    padding: 0 15px;
  }
  .back {
    padding-left: 80px;
    width: 100px;
  }
  .cards {
    max-width: 608px; // Максимальная ширина контейнера с 2 фото
    padding: 0 8px;
    gap: 16px;
  }
}

@media screen and (max-width: 650px) {
  .portfolio h2 {
    margin-top: 36px;
    margin-bottom: 41px;
    padding: 0 15px;
  }
  .cards {
    max-width: 290px; // Максимальная ширина контейнера с 2 фото
    padding: 0 8px;
    gap: 16px;
  }
}

@media screen and (max-width: 480px) {
  .portfolio {
    padding: 172px 0 25px 0;
  }
  .portfolio h2 {
    font-size: 20px;
    margin-bottom: 41px;
  }
  .back {
    padding-left: 20px;
    width: 84px;
  }
  .cards {
    max-width: 290px; // Максимальная ширина контейнера с 2 фото
    padding: 0 8px;
    gap: 16px;
  }
}
