@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.calc {
  padding: 257px 0 268px 0;
  background-color: #d9d0cd;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.calc img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.calc h2 {
  @include fontH2;
  color: $colorGrey;
  max-width: 561px;
  margin: 0 auto;
  margin-top: 30px;
  display: grid;
  grid-template-rows: auto auto;
}

.calc h2 .right {
  justify-self: flex-end;
}

.calc__title {
  text-align: center;
  margin-top: 44px;
  padding: 0 15px;
}

.hand {
  animation: advantages-hand 4s infinite
  }
  @keyframes advantages-hand {
  0% { transform: translateX(0) scale(3); opacity: 0 }
  33% { transform: translateX(0) scale(1); opacity: 1 }
  66% { transform: translateX(-50px) scale(1); opacity: 1 }
  100% { transform: translateX(0) scale(3); opacity: 0 }
  }

.calc__mix {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 146px 0 102px 0;
  padding: 0 80px;
}

.calc__mix_text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  color: #5f5b5a;
  max-width: 775px;
  margin-right: 150px;
  margin-left: 9%;
}

.calc__table {
  font-family: 'Cormorant', sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 122%;
  letter-spacing: 0.03em;
  color: #d2bdb4;
  padding: 0 80px;
  max-width: 1620px;
  margin: 0 auto;
}

.row_title {
  display: grid;
  grid-template-columns: 2.44fr 1.36fr 1fr 1fr 2.28fr;
  text-align: center;
  background-color: #5f5b5a;
  height: 180px;
}

.calc__table .name,
.medium,
.pro,
.vip,
.comments {
  border: 3px solid #d9d0cd;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-width: min-content;
}

.calc__table .comments {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row_main {
  display: grid;
  grid-template-columns: 2.44fr 1.36fr 1fr 1fr 2.28fr;
  text-align: center;
  background-color: #ffffff;
  color: #5f5b5a;
  height: 180px;
}

.row_main .medium,
.row_main .pro,
.row_main .vip {
  cursor: pointer;
}

.row_main .medium.active,
.row_main .pro.active,
.row_main .vip.active {
  background-color: #5f5b5a;
  color: #d2bdb4;
}

.row_main .comments {
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  text-align: left;
  padding: 0 42px;
}

.rowSummary {
  padding: 77px 15px 94px 15px;
  border: 3px solid #d9d0cd;
  display: flex;
  justify-content: center;
}

.sum {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 133%;
  color: #5f5b5a;
  background-color: white;
  box-sizing: border-box;
  width: 1020px;
  height: 92px;
  border: 3px solid #5f5b5a;
  border-radius: 30px;
}

.sumText {
  font-size: 20px;
  line-height: 150%;
  text-align: center;
}

@media screen and (max-width: 1649px) {
  .calc h2 {
    font-size: 44px;
    max-width: 514px;
  }
  .calc__mix {
    margin: 96px 0 144px 0;
  }
  .row_main .name, .row_title {
    font-size: 32px;
  }
  .row_main .comments {
    font-size: 16px;
  }
}

@media screen and (max-width: 1410px) {
  .calc h2 {
    font-size: 36px;
    max-width: 420px;
  }
  .calc__mix {
    margin: 48px 0 65px 0;
  }
  .calc__mix_text {
    font-size: 24px;
  }
  .row_title, .row_main, .row_main .name {
    font-size: 24px;
  }
  .row_main .comments {
    font-size: 16px;
  }
  .sum {
    gap: 8px;
    font-weight: 600;
    font-size: 20px;
    width: 1020px;
    height: 92px;
  }
  .calc {
    padding: 197px 0 208px 0;
  }
}

@media screen and (max-width: 1120px) {
  .back {
    padding-left: 70px;
  }
  .row_title, .row_main, .row_main .name {
    font-size: 24px;
  }
  .row_main .comments {
    font-size: 16px;
  }
  .sum {
    gap: 8px;
    font-weight: 600;
    font-size: 20px;
    width: 1020px;
    height: 92px;
  }
  .calc__table {
    padding: 0 20px;
  }
  .calc__mix {
    padding: 0 48px;
  }
  .calc__mix_text {
    margin-left: 5%;
  }
  .sum {
    width: 742px;
  }
}

@media screen and (max-width: 996px) {
  .row_title {
    grid-template-columns: 1fr 1fr 1fr; /* три колонки для средней строки */
    grid-template-rows: auto auto auto; /* три строки для компоновки */
    height: 100%;
  }
  .row_title .medium {
    height: 180px;
  }
  .row_title .name, .row_title .comments {
    grid-column: 1/ -1;
    height: 100px;
  }
  .row_main {
    grid-template-columns: 1fr 1fr 1fr; /* три колонки для средней строки */
    grid-template-rows: auto auto auto; /* три строки для компоновки */
    height: 100%;
  }
  .row_main .name {
    grid-column: 1/ -1;
    height: 100px;
  }
  .row_title, .row_main, .row_main .name {
    font-size: 32px;
  }
  .row_main .medium {
    height: 180px;
  }
  .row_main .comments {
    grid-column: 1/ -1;
    font-size: 20px;
    text-align: center;
    height: 100px;
    background-color: #d9d0cd;
  }
}

@media screen and (max-width: 820px) {
  .calc {
    padding: 157px 0 168px 0;
  }
  .calc h2 {
    font-size: 28px;
    max-width: 328px;
  }
  .calc__mix {
    margin: 64px 0 70px 0;
    padding: 0 30px;
  }
  .calc__mix_text {
    font-size: 20px;
  }
  .hand {
    animation: advantages-hand 4s infinite
    }
    @keyframes advantages-hand {
    0% { transform: translateX(0) scale(2); opacity: 0 }
    33% { transform: translateX(0) scale(1); opacity: 1 }
    66% { transform: translateX(-50px) scale(1); opacity: 1 }
    100% { transform: translateX(0) scale(2); opacity: 0 }
    }
    .row_title, .row_main, .row_main .name {
      font-size: 24px;
    }
    .row_title .medium, .row_main .medium {
      height: 150px;
    }
    .row_title .name, .row_title .comments, .row_main .name, .row_main .comments {
      height: 80px;
    }
    .row_main .comments {
      font-size: 16px;
    }
}

@media screen and (max-width: 820px) {
  .calc__mix_text {
    margin-right: 100px;
    margin-left: 0;
  }
}

@media screen and (max-width: 567px) {
  .calc__mix_text {
    margin-right: 50px;
    font-size: 16px;
  }
}


@media screen and (max-width: 480px) {
  .back {
    padding-left: 20px;
    width: 85px;
  }
  .calc__mix_text {
    margin-right: 0;
  }
  .hand {
    animation: advantages-hand 4s infinite
    }
    @keyframes advantages-hand {
    0% { transform: translateX(0) scale(1.5); opacity: 0 }
    33% { transform: translateX(0) scale(0.8); opacity: 1 }
    66% { transform: translateX(0) scale(0.8); opacity: 1 }
    100% { transform: translateX(0) scale(1.5); opacity: 0 }
    }
    .calc {
      padding: 97px 0 138px 0;
    }
    .calc h2 {
      font-size: 20px;
      max-width: 234px;
    }
    .calc__mix {
      margin: 32px 0 48px 0;
      padding: 0 15px;
    }
    .calc__table {
      padding: 0 15px;
    }
    .row_title, .row_main, .row_main .name {
      font-size: 20px;
    }
    .row_main .comments {
      font-size: 14px;
      padding: 0 10px;
    }
    .rowSummary {
      padding: 0;
    }
    
    .sum {
      gap: 5px;
      font-size: 16px;
    }
    
    .sumText {
      font-size: 16px;
    }
    .row_title .medium, .row_main .medium {
      height: 100px;
    }
    .row_title .name, .row_main .name {
      height: 60px;
    }
    .sum {
      border: 1px solid #5f5b5a;
    }
}