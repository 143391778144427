@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

.container {
  background-color: $colorGrey;
  &.evenContainer {
    background-color: $colorLight;
  }
}

.couple {
  max-width: 1920px;
  margin: 0 auto;
  padding: 465px 120px 322px 120px;
}

.back {
  margin-top: -208px;
  margin-bottom: 180px;
}

.text {
  color: $colorText;
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 40px;
  flex: none;
  &.evenText {
    color: $colorGrey;
  }

  .title {
    @include fontH2;
    box-sizing: border-box;
  }
}

.about_image {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 56px;
}

.about_image .original {
  max-width: 100%;
}

.InProgressContainer {
  margin: 0;
  padding: 0;
  font-family: 'montserrat';
  background-image: linear-gradient(
    125deg,
    #f9ecba,
    #9cdfe5,
    #a4ecb0,
    #c7a165,
    #fe907a,
    #e08aac,
    #d581e9
  );
  background-size: 400% 400%;
  animation: bganimation 14s infinite;
}

.InProgresText {
  font-family: sans-serif;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding-top: 400px;
  font-size: 1.5vw;
}
@keyframes bganimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 1690px) {
  .text {
    .title {
      font-size: 36px;
    }
  }
  .couple {
    padding: 465px 80px 189px 80px;
  }
  .back {
    padding-left: 40px;
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 1320px) {
  .text {
    .title {
      font-size: 28px;
    }
  }
  // .couple {
  //   padding: 425px 80px 189px 80px;
  // }
  .back {
    width: 111px;
  }
}

@media screen and (max-width: 996px) {
  .couple {
    padding: 365px 80px 189px 80px;
  }
  .back {
    padding-left: 0;
    width: 100px;
  }
}

@media screen and (max-width: 800px) {
  .couple {
    padding: 365px 80px 109px 80px;
  }
  .about_image {
    margin-top: 36px;
  }
}

@media screen and (max-width: 650px) {
  .couple {
    padding: 363px 17px 109px 17px;
  }
  .back {
    padding-left: 53px;
    width: 100px;
  }
}

@media screen and (max-width: 480px) {
  .text {
    .title {
      font-size: 20px;
    }
  }
  .couple {
    padding: 308px 17px 109px 17px;
  }
  .back {
    padding-left: 3px;
    width: 84px;
    margin-bottom: 24px;
  }
  .about_image {
    margin-top: 36px;
  }
}
