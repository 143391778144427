@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.intro {
  padding: 225px 0 56px 0;
  text-align: center;
  background: linear-gradient(to top, #5f5b5a 43%, #d9d0cd 43%);
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.intro_photo {
  margin-bottom: 250px;
  position: relative;
  padding: 0 60px;
}

.intro h3 {
  @include fontH2();
  text-align: center;
  color: $colorText;
}

.round_container {
  position: absolute;
  bottom: -185px;
  right: 28%;
}

.round {
  position: relative;
  top: 0;
  left: 0;
  animation: rotate 10s linear infinite; // Применяем анимацию rotate
}

.stroke {
  position: absolute;
  z-index: 1; // Убедитесь, что stroke находится поверх round
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1670px) {
  .round_container {
    right: 24%;
  }
  .intro h3 {
    font-size: 40px;
  }
}

@media screen and (max-width: 1320px) {
  .round_container {
    right: 17%;
  }
  .intro h3 {
    font-size: 32px;
  }
}

@media screen and (max-width: 1110px) {
  .round_container {
    max-width: 20%;
    bottom: -140px;
    right: 19%;
  }
  .intro h3 {
    font-size: 28px;
    padding: 0 25px;
  }
  .intro_photo {
    margin-bottom: 158px;
  }
  .stroke {
    max-width: 35%;
  }
}

@media screen and (max-width: 790px) {
  .intro {
    padding: 134px 0 36px 0;
    background: linear-gradient(to top, #5f5b5a 39%, #d9d0cd 39%);
  }
  .round_container {
    bottom: -96px;
    right: 27%;
  }
  .intro h3 {
    font-size: 24px;
    padding: 0 100px;
  }
  .intro_photo {
    margin-bottom: 128px;
  }
  .bw_main {
    content: url('../../../assets/images/BegunovaWedding/bw768.png');
  }
  .stroke {
    max-width: 27%;
  }
}

@media screen and (max-width: 650px) {
  .round_container {
    bottom: -79px;
    right: 23%;
  }
}

@media screen and (max-width: 590px) {
  .intro {
    padding: 134px 0 26px 0;
    background: linear-gradient(to top, #5f5b5a 37%, #d9d0cd 37%);
  }
  .round_container {
    bottom: -72px;
    right: 23%;
  }
  .intro h3 {
    font-size: 20px;
    padding: 0 50px;
  }
  .intro_photo {
    margin-bottom: 98px;
  }
}

@media screen and (max-width: 490px) {
  .round_container {
    bottom: -60px;
    right: 24%;
  }
}

@media screen and (max-width: 480px) {
  .intro {
    padding: 86px 0 26px 0;
  }
  .round_container {
    bottom: -60px;
    right: 27%;
  }
  .intro h3 {
    font-size: 16px;
    padding: 0 30px;
  }
  .intro_photo {
    padding: 0 16px;
    margin-bottom: 78px;
  }
  .bw_main {
    content: url('../../../assets/images/BegunovaWedding/bw320.png');
  }
  .stroke {
    max-width: 27%;
  }
}

@media screen and (max-width: 390px) {
  .round_container {
    bottom: -47px;
    right: 23%;
  }
  .intro_photo {
    margin-bottom: 68px;
  }
  .intro h3 {
    padding: 0 20px;
  }
}