@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.feedback {
  padding: 257px 0 290px 0;
  background-color: $colorGrey;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.feedback img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.feedback h2 {
  @include fontH2;
  color: $colorText;
  max-width: 702px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 84px;
  display: grid;
  grid-template-rows: auto auto;
}

.feedback h2 .right {
  justify-self: flex-end;
}

.feedback__action {
  display: flex;
  justify-content: space-between;
  padding: 0 240px 0 212px;
}

.feedback__cards {
  position: relative;
  min-height: 1493px;
  width: 124%;
  top: -150px;
  overflow: hidden;
}

.dragWrapper {
  position: absolute;
  // top: 0;
  // left: 17%;
  z-index: 1; /* Изначально все карточки на одном уровне */
  animation: spreadAndGather 10s ease-in-out forwards;
  animation-iteration-count: 4; /* Повторяем анимацию 5 раз */
}

@keyframes spreadAndGather {
  0% {
    top: 0;
    left: 17%;
  }
  50% {
    top: var(--x);
    left: var(--y);
  }
  100% {
    top: 0;
    left: 17%;
  }
}

.feedback__video {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  margin-top: 125px;
}

.video__button {
  @include subtitle_1;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 16px;
  width: 888px;
  background: rgba(210, 189, 180, 0.3);
  border: 3px solid $colorText;
  color: $colorWhite;
}

.hand__move,
.hand__mix {
  animation: advantages-hand 5s infinite;
}
@keyframes advantages-hand {
  0% {
    transform: translateX(0) scale(2);
    opacity: 0;
  }
  33% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  66% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(0) scale(2);
    opacity: 0;
  }
}

@media screen and (max-width: 1690px) {
  .feedback__cards {
    min-height: 1393px;
    width: 100%;
    top: -50px;
  }
  .feedback__video {
    margin-top: 25px;
  }
}

@media screen and (max-width: 1320px) {
  .dragWrapper {
    max-width: 90%;
  }
  .feedback__cards {
    min-height: 1293px;
    width: 100%;
    top: 0;
  }
  .feedback__video {
    margin-top: 0;
  }
  @keyframes spreadAndGather {
    0% {
      top: 0;
      left: 12%;
    }
    50% {
      top: var(--x);
      left: var(--y);
    }
    100% {
      top: 0;
      left: 12%;
    }
  }
  .feedback h2 {
    font-size: 44px;
    max-width: 640px;
    margin-top: 30px;
    margin-bottom: 64px;
  }
  .feedback {
    padding: 257px 0 200px 0;
  }
}

@media screen and (max-width: 1080px) {
  .dragWrapper {
    max-width: 80%;
  }
  .feedback__cards {
    min-height: 1093px;
    width: 100%;
    top: 0;
  }
  .feedback__video {
    margin-top: 0;
  }
  @keyframes spreadAndGather {
    0% {
      top: 0;
      left: 6%;
    }
    50% {
      top: var(--x);
      left: var(--y);
    }
    100% {
      top: 0;
      left: 6%;
    }
  }
  .feedback h2 {
    font-size: 44px;
    max-width: 640px;
    margin-top: 30px;
    margin-bottom: 64px;
  }
  .feedback {
    padding: 257px 0 200px 0;
  }
  .feedback__action {
    padding: 0 100px;
  }
  .hand__move,
  .hand__mix {
    animation: advantages-hand 5s infinite;
  }
  @keyframes advantages-hand {
    0% {
      transform: translateX(0) scale(1.5);
      opacity: 0;
    }
    33% {
      transform: translateX(0) scale(1);
      opacity: 1;
    }
    66% {
      transform: translateX(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(0) scale(1.5);
      opacity: 0;
    }
  }
}

@media screen and (max-width: 890px) {
  .back {
    padding-left: 80px;
    width: 100px;
  }
  .dragWrapper {
    max-width: 70%;
  }
  .feedback__cards {
    min-height: 640px;
    top: -100px;
  }
  @keyframes spreadAndGather {
    0% {
      top: 0;
      left: 12%;
    }
    50% {
      top: var(--x);
      left: var(--y);
    }
    100% {
      top: 0;
      left: 12%;
    }
  }
  .feedback h2 {
    font-size: 36px;
    max-width: 515px;
    margin-top: 40px;
    margin-bottom: 0;
  }
  .feedback {
    padding: 157px 0 150px 0;
  }
  .hand__move,
  .hand__mix {
    animation: advantages-hand 5s infinite;
  }
  @keyframes advantages-hand {
    0% {
      transform: translateX(0) scale(1);
      opacity: 0;
    }
    33% {
      transform: translateX(0) scale(0.5);
      opacity: 1;
    }
    66% {
      transform: translateX(0) scale(0.5);
      opacity: 1;
    }
    100% {
      transform: translateX(0) scale(1);
      opacity: 0;
    }
  }
  .feedback__video {
    padding: 0 90px;
  }
}

@media screen and (max-width: 680px) {
  .dragWrapper {
    max-width: 60%;
  }
  .feedback__cards {
    min-height: 540px;
    top: -80px;
  }
  @keyframes spreadAndGather {
    0% {
      top: 0;
      left: 17%;
    }
    50% {
      top: var(--x);
      left: var(--y);
    }
    100% {
      top: 0;
      left: 17%;
    }
  }
  .feedback h2 {
    font-size: 32px;
    max-width: 460px;
  }
  .feedback__action {
    padding: 0 50px;
  }
  // .back {
  //   padding-left: 60px;
  // }
  .video__button {
    font-size: 18px;
    padding: 16px;
  }
}

@media screen and (max-width: 495px) {
  .dragWrapper {
    max-width: 50%;
  }
  .feedback__cards {
    min-height: 380px;
    top: -80px;
  }
  @keyframes spreadAndGather {
    0% {
      top: 0;
      left: 20%;
    }
    50% {
      top: var(--x);
      left: var(--y);
    }
    100% {
      top: 0;
      left: 20%;
    }
  }
  .feedback h2 {
    font-size: 28px;
    max-width: 395px;
  }
  .feedback__action {
    padding: 0;
  }
  .back {
    padding-left: 20px;
    width: 84px;
  }
  .feedback__video {
    padding: 0 30px;
  }
  .feedback {
    padding: 157px 0 100px 0;
  }
  .hand__move,
  .hand__mix {
    animation: advantages-hand 5s infinite;
  }
  @keyframes advantages-hand {
    0% {
      transform: translateX(0) scale(1);
      opacity: 0;
    }
    33% {
      transform: translateX(0) scale(0.8);
      opacity: 1;
    }
    66% {
      transform: translateX(0) scale(0.8);
      opacity: 1;
    }
    100% {
      transform: translateX(0) scale(1);
      opacity: 0;
    }
  }
}

@media screen and (max-width: 460px) {
  .dragWrapper {
    max-width: 60%;
  }
  .feedback__cards {
    min-height: 280px;
    top: -50px;
  }
  @keyframes spreadAndGather {
    0% {
      top: 0;
      left: 22%;
    }
    50% {
      top: var(--x);
      left: var(--y);
    }
    100% {
      top: 0;
      left: 22%;
    }
  }
  .feedback h2 {
    font-size: 24px;
    max-width: 280px;
  }
  .feedback__action svg {
    width: 80%;
    height: 80%;
  }
  .feedback__video {
    padding: 0 15px;
  }
  .feedback {
    padding: 97px 0 50px 0;
  }
  .video__button {
    font-size: 16px;
    padding: 8px;
  }
}
