@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.adv_main {
  padding: 0 0 54px 0;
  background-color: $colorGrey;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.adv_main img {
  max-width: 100%;
}

.adv_main .container {
  max-width: 1920px;
  margin: 0 auto;
}

.adv_main h2 {
  @include fontH2;
  color: $colorText;
  max-width: 403px;
  margin: 0 auto;
  margin-bottom: 68px;
  display: grid;
  grid-template-rows: auto auto;
}

.adv_main h2 .right {
  justify-self: flex-end;
}

.adv_info {
  display: flex;
  justify-content: center;
  gap: 22px;
  /*flex-wrap: wrap;*/
  align-items: baseline;
  padding: 0 15px;
}

// @media screen and (max-width: 1100px) {
//   .adv_info {
//     flex-wrap: wrap;
//   }
// }

.info_card_txt {
  max-width: 548px;
}

.card_main_txt {
  @include body2;
  color: $colorText;
  margin-top: 30px;
  padding-left: 130px;
}

.card_add_txt {
  @include subtitle_1;
  color: $colorText;
  margin-top: 36px;
  padding-left: 130px;
}

.adv_preparation {
  padding: 54px 0 120px 0;
  background-color: $colorGrey;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.adv_preparation .container {
  max-width: 1920px;
  margin: 0 auto;
}

.adv_prep_title {
  max-width: 1136px;
  display: flex;
  margin: 0 auto 56px auto;
  justify-content: space-between;
  padding: 0 15px;
  flex-wrap: wrap;
}

.adv_prep_title img {
  max-width: 100%;
}

.adv_prep_title_switch {
  display: flex;
  align-items: center;
  gap: 20px;
}

.adv_prep_main {
  display: flex;
  justify-content: center;
  padding: 101px 0 134px 0;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url('../../assets/images/Advantage/prep_main.png');
  // mix-blend-mode: luminosity;
  filter: grayscale(100%);
  background-size: cover;
  background-position: center;
}

.adv_prep_main_way {
  padding: 0 20px 0 10px;
}

.adv_prep_main_way img {
  max-width: 100%;
}

.adv_prep_title_switch .point {
  cursor: pointer;
}

.adv_prep_main.active {
  /*background: url("../images/guarantees/prep_main.png");*/
  background-position: center;
  background-size: cover;
  // mix-blend-mode: normal;
  filter: grayscale(0);
}

.hand {
  animation: advantages-hand 4s infinite
  }
  @keyframes advantages-hand {
  0% { transform: translateX(0) scale(3); opacity: 0 }
  33% { transform: translateX(0) scale(1); opacity: 1 }
  66% { transform: translateX(-50px) scale(1); opacity: 1 }
  100% { transform: translateX(0) scale(3); opacity: 0 }
  }

  @media screen and (max-width: 1680px) {
    .adv_main h2 {
      max-width: 370px;
      font-size: 44px;
    }
    .card_main_txt {
      padding-left: 0;
      font-size: 20px;
      line-height: 150%;
    }
    .adv_info {
      gap: 80px;
      padding: 0 30px;
    }
    .card_add_txt {
      padding-left: 0;
      font-size: 16px;
    }
    .adv_main {
      padding: 0 0 87px 0;
    }
  }

  @media screen and (max-width: 1300px) {
    .adv_main h2 {
      max-width: 303px;
      font-size: 36px;
    }
    .adv_info {
      gap: 24px;
      padding: 0 30px;
    }
  }
@media screen and (max-width: 970px) {
  .adv_prep_title {
    display: block;
    padding: 0 25px;
    margin: 0 auto 36px auto;
  }
  .adv_prep_title_txt {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .adv_prep_title_switch {
    display: flex;
    justify-content: end;
    padding-right: 10px;
  }
  .adv_prep_main {
    padding: 50px 0;
  }
  .point svg{
    width: 120%;
    height: auto;
  }
}


  @media screen and (max-width: 930px) {
    .adv_main h2 {
      max-width: 268px;
      font-size: 32px;
      margin-bottom: 57px;
    }
    .card_main_txt {
      padding-left: 5px;
      font-size: 16px;
    }
    .adv_info {
      gap: 5px;
      padding: 0 30px;
    }
    .adv_main {
      padding: 0 0 21px 0;
    }
  }

  @media screen and (max-width: 780px) {
    .adv_main h2 {
      max-width: 249px;
      font-size: 28px;
    }
    .card_main_txt {
      padding-left: 5px;
      font-size: 16px;
    }
    .adv_info {
      gap: 5px;
      padding: 0 30px;
    }
    .adv_main {
      padding: 0 0 21px 0;
    }
  }

  @media screen and (max-width: 650px) {
    .adv_main h2 {
      max-width: 375px;
      font-size: 44px;
      margin-bottom: 50px;
    }
    .card_main_txt {
      padding: 0 60px;
      font-size: 24px;
    }
    .adv_info {
      gap: 48px;
      padding: 0 30px;
       flex-wrap: wrap;
    }
    .adv_main {
      padding: 0 0 21px 0;
    }
    .info_card_img {
      max-width: 100%;
      height: auto;
    }
    .card1 {
      content: url(../../assets/images/Guarantees/card1_650.png);
    }
    .card2 {
      content: url(../../assets/images/Guarantees/card2_650.png);
    }
    .card3 {
      content: url(../../assets/images/Guarantees/card3_650.png);
    }
    .card_add_txt {
      padding: 0 60px;
      font-size: 20px;
    }
  }

  @media screen and (max-width: 510px) {
    .adv_main h2 {
      max-width: 308px;
      font-size:36px;
    }
    .card_main_txt {
      font-size: 20px;
    }
    .card_add_txt {
      font-size: 16px;
    }
    .adv_info {
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 375px) {
    .adv_main h2 {
      max-width: 202px;
      font-size:24px;
    }
    .card_main_txt {
      font-size: 16px;
      padding: 0 40px;
    }
    .card_add_txt {
      font-size: 14px;
      padding: 0 40px;
      margin-top: 20px;
    }
  }