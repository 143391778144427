@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.history {
    background-color: #5F5B5A;
    font-size: 0;
}

.history img {
    max-width: 100%;
    height: auto;
}

.container {
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 180px 94px;
    flex-wrap: nowrap;
}

.history_main {
    display: flex;
    flex-direction: column;
}

.history_text {
    font-family: Helvetica, sans-serif;
    font-size: 28px;
    line-height: 143%;
    letter-spacing: 0.03em;
    color: #D2BDB4;
    max-width: 803px;
    /*min-height: 766px;*/
    margin-right: 45px;
    margin-bottom: 82px;
}

.Letter {
    font-size: 120px;
    line-height: 1;
    float: left;
    margin-right: 8px;
}

.history_text_all {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    min-height: 640px;
}

.history_video {
    position: relative;
    display: flex;
    align-self: center;
    width: 551px; // Устанавливаем ширину для видео
    height: auto; // Устанавливаем высоту для видео
    .video {
        width: 100%;  // Видео будет занимать 100% ширины родительского контейнера
        height: 100%; // Видео будет занимать 100% высоты родительского контейнера
        object-fit: cover; // Видео будет обрезаться, чтобы соответствовать заданным размерам
      }
}

.history_video img {
    max-width: 100%;
}

.history_video .small {
    position: absolute;
    left: -70px;
    bottom: -80px;
}

.history_video .big {
    position: absolute;
    left: 490px;
    bottom: 10px;
}

.history_photo {
    display: flex;
    flex-direction: column;
}

.history_image {
    display: flex;
    position: relative;
}

.history_image .original {
    max-width: 100%;
    /*min-height: 543px;*/
}

.history_image .small {
    position: absolute;
    top: -67px;
    right: -60px;
}

.history_signature {
    text-align: center;
    margin-top: 20%;
}

@media screen and (max-width: 1660px) {
    .container {
        padding: 200px 80px 100px 80px;
    }
    .Letter {
        font-size: 160px;
    }
    .history_main {
        gap: 50px;
    }
    .history_signature {
        margin-top: 30%;
    }
}

@media screen and (max-width: 1300px) {
    .container {
        padding: 200px 70px 100px 70px;
    }
    .Letter {
        font-size: 170px;
    }
    .history_signature {
        margin-top: 40%;
    }
    .history_text {
        font-size: 24px;
        margin-right: 30px;
        margin-bottom: 145px;
    }
    .history_photo .small {
        display: none;
    }
    .history_video {
        width: 504px;
        height: auto;
    }
    .history_video .big {
        max-width: 80%;
        left: 85%;
        bottom: 4%;
    }
}

@media screen and (max-width: 1100px) {
    .container {
        padding: 100px 70px;
    }
    .history_text {
        font-size: 20px;
        margin-right: 20px;
        margin-bottom: 100px;
    }
    .history_video .small {
        display: none;
    }
    .history_signature {
        padding-left: 25%;
        max-width: 70%;
        margin-top: 80%;
    }
    .history_text_all {
        min-height: 0;
        gap: 30px;
        width: 393px;
    }
    .history_video .big {
        bottom: 6%;
    }
    .history_video {
        width: 428px;
        height: auto;
    }
}

@media screen and (max-width: 875px) {
    .container {
        padding: 80px 50px;
    }
    .history_main {
        gap: 50px;
    }
    .Letter {
        font-size: 125px;
    }
    .history_text {
        font-size: 16px;
        margin-right: 15px;
        margin-bottom: 50px;
        width: 320px;
    }
    .history_text_all {
        gap: 20px;
        width: 320px;
    }
    .history_signature {
        max-width: 60%;
        margin-top: 15%;
    }
    .history_video {
        justify-content: end;
        width: 329px;
        height: auto;
    }
    .history_video .video {
        width: 85%;
    }
    .history_video .big {
        max-width: 60%;
    }
}

@media screen and (max-width: 670px) {
    .container {
        padding: 80px 35px;
        flex-wrap: wrap;
        gap: 50px;
    }
    .Letter {
        font-size: 48px;
        margin-right: 5px;
    }
    .history_text {
        font-size: 16px;
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
        order: 1;
    }
    .history_text_all {
        gap: 20px;
        width: 100%;
    }
    .history_video .big {
        display: none;
    }
    .history_video {
        width: 504px;
        order: 3;
    }
    .history_photo {
        flex-direction: row;
        gap: 30px;
        order: 2;
    }
    .history_image {
        max-width: 55%;
    }
    .history_signature {
        display: flex;
        align-items: center;
        max-width: 100%;
        padding-left: 0;
        margin-top: 0;
    }
}

@media screen and (max-width: 520px) {
    .container {
        padding: 20px 15px;
        flex-wrap: wrap;
        gap: 30px;
    }
    .Letter {
        font-size: 42px;
        margin-right: 2px;
    }
    .history_text {
        font-size: 14px;
    }
    .history_text_all {
        gap: 15px;
    }
    .history_video {
        width: 428px;
    }
    .history_photo {
        gap: 20px;
    }
    .history_image {
        max-width: 55%;
    }
}

@media screen and (max-width: 450px) {
    .history_video {
        width: 329px;
    }
    .Letter {
        font-size: 36px;
        line-height: 110%;
    }
}

@media screen and (max-width: 350px) {
    .history_video {
        width: 270px;
    }
}