@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';


* {
    margin: 0;
    padding: 0;
}

.wedding_in_world {
    background-color: #5F5B5A;
    padding: 52px 0;
}

.wedding_in_world img {
    max-width: 100%;
    height: auto;
}

.container {
    max-width: 1920px;
    margin: 0 auto;
}

.content {
    display: flex;
    justify-content: center;
    padding: 0 50px;
}

@media screen and (max-width: 700px) {
    .content img {
        content: url('../../../assets/images/Begunova/wedding_700.png');
    }
    .content {
        padding: 0 5%;
    }
}
