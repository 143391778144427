@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.unique {
  font-size: 0;
  background: $colorLight;
}

.unique img{
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 100px 80px 70px 80px;
  text-align: center;
}

@media screen and (max-width: 1310px) {
  .unique__image {
    content: url('../../../assets/images/Unique/allunique1280.png');
  }
  .container {
    padding: 95px 50px;
  }
}

@media screen and (max-width: 860px) {
  .unique__image {
    content: url('../../../assets/images/Unique/allunique860.png');
  }
  .container {
    padding: 44px 40px 76px 40px;
  }
}

@media screen and (max-width: 580px) {
  .unique__image {
    content: url('../../../assets/images/Unique/allunique470.png');
  }
  .container {
    padding: 40px 16px;
  }
}

// .unique h2 {
//   @include fontH2;
//   color: $colorGrey;
//   max-width: 616px;
//   margin: 0 auto;
//   display: grid;
//   grid-template-rows: auto auto;
// }

// .unique h2 .right {
//   justify-self: flex-end;
// }

// .unique_info {
//   margin-top: 80px;
//   display: flex;
// }

// .unique_info_text {
//   @include body1;
//   color: $colorGrey;
//   max-width: 620px;
//   padding-left: 102px;

//   .firstLetterText {
//     font-size: 120px;
//     line-height: 1;
//     float: left;
//     margin-right: 8px;
//   }
// }

// .unique_info_images {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   position: relative;
//   width: 100%;
//   height: 1200px;
//   /*overflow: hidden;*/
// }

// .unique_image {
//   display: flex;
//   /*width: 100%;*/
// }

// .unique_image.image1 {
//   position: absolute;
//   display: flex;
//   top: 316px;
//   left: -70px;
// }

// .unique_image .image2 {
//   position: absolute;
//   left: 355px;
// }

// .unique_image.image3 {
//   position: absolute;
//   left: 637px;
//   top: 392px;
// }

// .unique_image.image1 .small {
//   position: absolute;
//   bottom: -85px;
//   left: -70px;
// }

// .unique_image.image1 .big {
//   position: absolute;
//   top: 180px;
//   left: -445px;
// }

// .unique_image.image3 .small {
//   position: absolute;
//   top: -70px;
//   right: -70px;
// }
