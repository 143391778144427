@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.header {
  background-color: rgba($colorDark, 0.7);
  font-size: 0;
  position: sticky;
  top: 0;
  z-index: 100;
}

.headerContent {
  max-width: 1920px;
  margin: 0 auto;
  padding: 19px 67px 29px 82px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.utilsContainer {
  display: flex;
  width: 230px;
  justify-content: flex-end;
  padding-left: 35px;
}

.title {
  padding-right: 10px;
  color: $colorLight;
}

@media screen and (max-width: 1650px) {
  .headerContent {
    max-width: 1920px;
    margin: 0 auto;
    padding: 19px 30px 29px 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    padding-right: 0;
  }

  .utilsContainer {
    width: fit-content;
    padding-left: 50px;
  }

  .title img {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1290px) {
  .title {
    width: 273px;
  }
  .headerContent {
    padding: 28px 30px 37px 30px;
  }
}

@media screen and (max-width: 1230px) {
  .title {
    width: 154px;
  }
  .headerContent {
    padding: 46px 27px;
  }
  .utilsContainer {
    padding-left: 0;
  }
}

@media screen and (max-width: 996px) {
  .title {
    width: 167px;
  }
  .headerContent {
    padding: 30px 27px;
  }
  .utilsContainer {
    display: none;
  }
}

@media screen and (max-width: 820px) {
  .title {
    width: 177px;
  }
  .headerContent {
    padding: 26px 40px;
  }
  .utilsContainer {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .title {
    width: 128px;
  }
  .headerContent {
    padding: 16px;
  }
}
