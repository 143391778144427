// .InProgressContainer{
//   margin: 0;
//   padding: 0;
//   font-family: "montserrat";
//   background-image: linear-gradient(125deg,#f9ecba,#9cdfe5,#a4ecb0,#C7A165,#FE907A,#E08AAC,#d581e9);
//   background-size: 400% 400%;
//   animation: bganimation 14s infinite;
// }
// .InProgresText{
// 	font-family: sans-serif;
//   color: white;
//   text-align: center;
//   text-transform: uppercase;
//   padding-top: 200px;
//   font-size: 1.5vw;
// }
// @keyframes bganimation{
//     0%{
//       background-position: 0% 50%;
//     }
//     50%{
//       background-position: 100% 50%;
//     }
//     100%{
//       background-position: 0% 50%;
//     }
// }
@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';


* {
  margin: 0;
  padding: 0;
}

.inprogress {
  padding: 257px 0 566px 0;
  background-color: #d9d0cd;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.inprogress img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.inprogress__main {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 170px;
  padding: 0 30px;
}

.inprogress__img {
  margin-bottom: 180px;
  padding: 0 70px
}

.inprogress h5 {
  @include fontH2;
  color: $colorGrey;
  margin-bottom: 30px;
}

.inprogress p {
  @include body1;
  font-size: 36px;
  color: $colorGrey;
}

@media screen and (max-width: 1649px) {
  .inprogress h5 {
    font-size: 44px;
  }
  .inprogress p {
    font-size: 32px;
  }
}

@media screen and (max-width: 1410px) {
  .inprogress {
    padding: 257px 0 290px 0;;
  }
  .inprogress__main {
    margin-top: 120px;
  }
  .inprogress__img {
    margin-bottom: 130px;
  }
  .inprogress h5 {
    font-size: 36px;
  }
  .inprogress p {
    font-size: 28px;
  }
}

@media screen and (max-width: 1120px) {
  .back {
    padding-left: 70px;
  }
}

@media screen and (max-width: 820px) {
  .inprogress {
    padding: 157px 0 366px 0;
  }
  .inprogress__main {
    margin-top: 100px;
  }
  .inprogress__img {
    margin-bottom: 100px;
  }
  .inprogress h5 {
    font-size: 28px;
  }
  .inprogress p {
    font-size: 20px;
  }
}

@media screen and (max-width: 480px) {
  .inprogress {
    padding: 97px 0 266px 0;
  }
  .inprogress__main {
    margin-top: 50px;
  }
  .inprogress__img {
    margin-bottom: 70px;
  }
  .back {
    padding-left: 20px;
    width: 85px;
  }
  .inprogress h5 {
    font-size: 20px;
  }
  .inprogress p {
    font-size: 16px;
  }
}