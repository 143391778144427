@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

.about_us {
  padding: 257px 0 302px 0;
  background-color: $colorGrey;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  margin-bottom: 76px;
  padding-left: 120px;
}

.section {
  margin-left: 120px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.text_all {
  @include body1;
  color: $colorText;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.text_1 {
  border-top: 1px solid $colorText;
  padding-top: 20px;
  margin-top: 512px;
  max-width: 564px;

  .down {
    margin-top: 40px;
  }
}

.text_2 {
  border-top: 1px solid $colorText;
  padding-top: 20px;
  max-width: 645px;
  margin-top: 400px;

  .down {
    padding-top: 20px;
    border-top: 1px solid $colorText;
    margin-top: 430px;
  }
}

.image {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.about_image {
  margin-left: -233px;
}

.glass_image {
  margin-top: 128px;
  margin-right: 59px;
}

@media screen and (max-width: 1720px) {
  .about_us {
    padding: 257px 0 302px 0;
  }
  .back {
    margin-bottom: 76px;
    padding-left: 120px;
  }
  .section {
    margin-left: 100px;
  }
  .text_all {
    font-size: 24px;
  }
  .text_1 {
    margin-top: 512px;
    max-width: 564px;
    min-height: 360px;
    .down {
      margin-top: 40px;
    }
  }
  .text_2 {
    max-width: 645px;
    margin-top: 400px;
    .down {
      margin-top: 480px;
    }
  }
  .about_image {
    margin-left: -283px;
  }
  .glass_image {
    margin-right: 49px;
    width: 576px;
    height: 1103px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1478px) {
  .about_us {
    padding: 257px 0 302px 0;
  }
  .back {
    margin-bottom: 76px;
    padding-left: 120px;
  }
  .section {
    margin-left: 76px;
  }
  .text_all {
    font-size: 20px;
  }
  .text_1 {
    margin-top: 512px;
    max-width: 477px;
    .down {
      margin-top: 40px;
    }
  }
  .text_2 {
    max-width: 478px;
    margin-top: 400px;
    .down {
      margin-top: 280px;
    }
  }
  .about_image {
    margin-left: -243px;
    width: 920px;
  }
  .glass_image {
    margin-top: 180px;
    margin-right: 74px;
    height: 864px;
  }
}

@media screen and (max-width: 1160px) {
  .about_us {
    padding: 257px 0 302px 0;
  }
  .back {
    margin-bottom: 76px;
    padding-left: 120px;
  }
  .section {
    margin-left: 70px;
  }
  .text_all {
    font-size: 16px;
  }
  .text_1 {
    margin-top: 250px;
    max-width: 420px;
    .down {
      margin-top: 20px;
    }
  }
  .text_2 {
    max-width: 414px;
    margin-top: 240px;
    .down {
      margin-top: 230px;
    }
  }
  .about_image {
    margin-left: -193px;
    width: 690px;
  }
  .glass_image {
    margin-top: 123px;
    margin-right: 74px;
    height: 632px;
    width: 421px;
  }
}

@media screen and (max-width: 990px) {
  .about_us {
    padding: 157px 0 302px 0;
  }
  .back {
    margin-bottom: 28px;
    padding-left: 80px;
    width: 100px;
  }
  .section {
    margin-left: 64px;
  }
  .text_all {
    font-size: 16px;
  }
  .text_1 {
    margin-top: 250px;
    max-width: 219px;
    .down {
      margin-top: 20px;
    }
  }
  .text_2 {
    max-width: 285px;
    margin-top: 166px;
    .down {
      margin-top: 110px;
    }
  }
  .about_image {
    margin-left: -203px;
    width: 690px;
  }
  .glass_image {
    margin-top: 166px;
    margin-right: 29px;
    height: 462px;
    width: 340px;
  }
}

@media screen and (max-width: 750px) {
  .about_us {
    padding: 150px 0 150px 0;
  }
  .back {
    margin-bottom: 28px;
    width: 84px;
    padding-left: 20px;
  }
  .section {
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
  }
  .text_all {
    font-size: 14px;
  }
  .text_1 {
    margin-top: 220px;
    max-width: 179px;
    .down {
      margin-top: 20px;
    }
  }
  .text_2 {
    max-width: 185px;
    margin-top: 5px;
    .down {
      margin-top: 110px;
    }
  }
  .about_image {
    margin-left: -153px;
    width: 490px;
  }
  .glass_image {
    margin-top: 5px;
    margin-right: 20px;
    height: 442px;
    width: 300px;
  }
}

@media screen and (max-width: 547px) {
  .text_2 {
    margin-top: 20px;
    .down {
      margin-top: 50px;
    }
  }
  .about_image {
    margin-left: -163px;
    width: 450px;
  }
  .glass_image {
    margin-top: 20px;
    height: 400px;
    width: 228px;
  }
}

@media screen and (max-width: 480px) {
  .about_us {
    padding: 100px 0 82px 0;
  }
  .section {
    margin-left: 0;
    margin-right: 0;
  }
  .text_all {
    font-size: 14px;
  }
  .text_1 {
    margin-top: 70px;
    max-width: 235px;
    .down {
      margin-top: 20px;
    }
  }
  .text_2 {
    max-width: 285px;
    margin-top: 32px;
    .down {
      margin-top: 32px;
    }
  }
  .about_image {
    margin-left: -237px;
    width: 270px;
  }
  .glass_image {
    margin-top: 20px;
    height: 400px;
    width: 288px;
  }
}
