@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.prep_main {
  // padding: 98px 0 0 0;
  background-color: $colorGrey;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.prep_main h2 {
  @include fontH2;
  color: $colorText;
  max-width: 404px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto auto;
}

.prep_main h2 .right {
  justify-self: flex-end;
}

.prep_main img {
  max-width: 100%;
  height: auto;
}

.prep_main .container {
  max-width: 1920px;
  margin: 0 auto;
}

.prep_main_title {
  padding: 90px 0;
}

.prep_info_img {
  background-image: url('../../../assets/images/Preparations/prep_main_photo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.prep_info {
  padding: 23px 0 107px 0;
  text-align: center;
}

.prep_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 73px;
  padding: 0 20px;
  margin-bottom: 205px;
}

.prep_map1 {
  margin-bottom: 216px;
}

.prep_map2,
.prep_map1 {
  padding: 0 100px;
}

.prep_main_text {
  padding: 108px 0 0 0;
  background-color: $colorGrey;
}

.prep_main_text .container {
  max-width: 1920px;
  margin: 0 auto;
}

.prep_main_content {
  margin: 0 auto;
}

.prep_content_title {
  text-align: center;
  font-family: 'Cormorant', sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.03em;
  color: $colorText;
  margin-bottom: 84px;
  padding: 0 15px;
}

.prep_content_title .title {
  margin-bottom: 50px;
}

.prep_content_list {
  padding: 0 260px;
}

.prep_content_list ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 150%;
  color: $colorText;
  margin-bottom: 64px;
}

.prep_content_list span {
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 214%;
  color: $colorText;
}

@media screen and (max-width: 1690px) {
  .prep_content_list {
    padding: 0 108px;
  }
  .prep_content_list ul {
    font-size: 24px;
    margin-bottom: 64px;
  }
  .prep_content_list span {
    font-size: 24px;
  }
  .prep_main h2 {
    font-size: 44px;
    max-width: 370px;
  }
  .prep_main_title {
    padding: 70px 0;
  }
}

@media screen and (max-width: 1400px) {
  .prep_content_list {
    padding: 0 108px;
  }
  .prep_content_list ul {
    font-size: 20px;
    margin-bottom: 32px;
  }
  .prep_content_list span {
    font-size: 20px;
    line-height: 160%;
  }
  .prep_main h2 {
    font-size: 36px;
    max-width: 303px;
  }
  .prep_content_title {
    font-size: 28px;
    margin-bottom: 56px;
  }
  .prep_content_title .title {
    margin-bottom: 40px;
  }
  .prep_main_title {
    padding: 60px 0;
  }
}

@media screen and (max-width: 1100px) {
  .prep_content_list {
    padding: 0 73px;
  }
  .prep_main h2 {
    font-size: 32px;
    max-width: 270px;
  }
  .prep_logo {
    margin-bottom: 90px;
  }
  .prep_main_text {
    padding: 53px 0 0 0;
  }
}

@media screen and (max-width: 920px) {
  .prep_main h2 {
    font-size: 28px;
    max-width: 247px;
  }
  .prep_logo {
    margin-bottom: 67px;
    gap: 30px;
  }
  .prep_map2,
  .prep_map1 {
    padding: 0 30px;
  }
}

@media screen and (max-width: 800px) {
  .prep_main_text {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .prep_main h2 {
    font-size: 24px;
    max-width: 196px;
  }
  .prep_map1 {
    content: url('../../../assets/images/Preparations/prep_map1_mob.png');
    margin: 0 auto;
    padding-bottom: 48px;
  }
  .prep_map2 {
    content: url('../../../assets/images/Preparations/prep_map2_mob.png');
    margin: 0 auto;
  }
  .prep_logo {
    margin-bottom: 20px;
    gap: 10px;
  }
  .prep_main_text {
    display: block;
    padding-top: 33px;
  }
  .prep_content_title {
    font-size: 16px;
    margin-bottom: 24px;
    padding: 0 40px;
  }
  .prep_content_title .title {
    margin-bottom: 25px;
  }
  .prep_content_list {
    padding: 0 38px;
  }
  .prep_content_list ul {
    font-size: 14px;
    margin-bottom: 22px;
  }
  .prep_content_list span {
    font-size: 14px;
    line-height: 160%;
  }
}

@media screen and (max-width: 450px) {
  .prep_main h2 {
    font-size: 20px;
    max-width: 168px;
  }
  .prep_map2,
  .prep_map1 {
    padding: 0 0 48px 0;
  }
  .prep_info {
    padding: 23px 0 57px 0;
  }
}