@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.vfb {
  padding: 257px 0 306px 0;
  background-color: $colorLight;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.vfb img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.vfb h2 {
  @include fontH2;
  color: $colorGrey;
  max-width: 325px;
  margin: 0 auto;
  margin-top: 30px;
  display: grid;
  grid-template-rows: auto auto;
}

.vfb h2 .right {
  justify-self: flex-end;
}

.vfb__title {
  text-align: center;
  margin-top: 44px;
  padding: 0 15px;
}

.vfb__main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
  margin: 92px auto 120px auto;
  padding: 0 25px;
  max-width: 1680px;
}

.vfb__video {
  border: 1px solid $colorGrey;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 82%;
}

.video_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vfb__video:nth-child(1) video {
  object-position: 0 16%;
}

.vfb__video:nth-child(6) video {
  object-position: 0 30%;
}

.vfb__video:nth-child(8) video {
  object-position: 0 24%;
}

.hidden {
  display: none;
}

.vfb__action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}

.vfb__toggle {
  @include subtitle_1;
  box-sizing: border-box;
  padding: 16px;
  width: 1114px;
  background: rgba(76, 73, 72, 0.3);
  border: 3px solid rgba(76, 73, 72, 0.7);
  cursor: pointer;
  color: $colorWhite;
}

@media screen and (max-width: 1300px) {
  .vfb h2 {
    font-size: 40px;
    max-width: 272px;
  }
  .vfb__toggle {
    width: 814px;
  }
}

@media screen and (max-width: 1050px) {
  .vfb h2 {
    font-size: 36px;
    max-width: 235px;
  }
  .vfb__toggle {
    width: 614px;
  }
  .vfb {
    padding: 157px 0 206px 0;
  }
}

@media screen and (max-width: 900px) {
  .vfb__main {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    margin: 62px auto 90px auto;
    padding: 0 75px;
  }
}

@media screen and (max-width: 810px) {
  .vfb h2 {
    font-size: 30px;
    max-width: 200px;
  }
  .vfb__toggle {
    width: 514px;
  }
  .vfb {
    padding: 157px 0 206px 0;
  }
  .back {
    padding-left: 30px;
    width: 100px;
  }
}

@media screen and (max-width: 650px) {
  .vfb__main {
    padding: 0 25px;
  }
  .vfb__toggle {
    width: 314px;
  }
  .vfb__main {
    margin: 62px auto 70px auto;
  }
  .vfb {
    padding: 157px 0 106px 0;
  }
}

@media screen and (max-width: 480px) {
  .vfb h2 {
    font-size: 24px;
    max-width: 160px;
  }
  .back {
    padding-left: 20px;
    width: 84px;
  }
  .vfb__toggle {
    width: 214px;
    font-size: 18px;
  }
  .vfb {
    padding: 97px 0 66px 0;
  }
}
