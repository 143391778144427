@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.statistic {
  font-size: 0;
  background-image: url('../../../assets/images/InNumbers/statistics.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 137px 0 256px 0;
}

.stat_point img {
  max-width: 100%;
  height: auto;
}

.statistic h3 {
  font-family: Cormorant, sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 133%;
  text-align: center;
  letter-spacing: 0.03em;
  color: $colorText;
  margin-bottom: 103px;
}

.stat__info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 50px;
  gap: 56px;
}

.stat_point {
  display: flex;
}

@media screen and (max-width: 1670px) {
  .statistic h3 {
    font-size: 44px;
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 1330px) {
  .container {
    padding: 167px 0;
  }
  .statistic h3 {
    font-size: 36px;
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 1110px) {
  .container {
    padding: 123px 0;
  }
  .statistic h3 {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .stat__info {
    padding: 0 50px;
    gap: 32px;
  }
}

@media screen and (max-width: 960px) {
  .container {
    padding: 83px 0 138px 0;
  }
  .statistic h3 {
    font-size: 32px;
    margin-bottom: 28px;
  }
  .stat__info {
    padding: 0 36px;
    gap: 8px;
  }
}

@media screen and (max-width: 770px) {
  .container {
    padding: 92px 0 157px 0;
  }
  .statistic h3 {
    font-size: 28px;
    margin-bottom: 28px;
  }
  .stat__info {
    padding: 0 36px;
    gap: 8px;
  }
}

@media screen and (max-width: 670px) {
  .container {
    padding: 92px 0 157px 0;
  }
  .statistic h3 {
    font-size: 28px;
    margin-bottom: 28px;
  }
  .stat__info {
    padding: 0 60px;
    flex-wrap: wrap;
    gap: 12px;
  }
  .stat__point2 {
    padding: 0 50px;
  }
  .stat__point3 {
    padding: 0 30px;
  }
  .stat__point4 {
    padding: 0 30px;
  }
}

@media screen and (max-width: 490px) {
  .container {
    padding: 30px 0;
  }
  .statistic h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .statistic {
    background: none;
    background-color: #5F5B5A;
  }
  .stat__info {
    padding: 0 20px;
    gap: 0;
    max-width: 330px;
    margin: auto;
  }
  .stat__point {
    padding: 0;
  }
  .stat__point1 {
    content: url(../../../assets/images/InNumbers/stat1_350.png);
  }
  .stat__point2 {
    content: url(../../../assets/images/InNumbers/stat2_350.png);
  }
  .stat__point3 {
    content: url(../../../assets/images/InNumbers/stat3_350.png);
  }
  .stat__point4 {
    content: url(../../../assets/images/InNumbers/stat4_350.png);
  }
}

