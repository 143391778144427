@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.component {
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  display: inline-block;
  overflow: hidden; /* Обрезаем все, что выходит за границы контейнера */
}

.size {
  width: 326px;
  height: 510px;
  &__pics {
    width: 549px;
    height: 712px;
  }
  &__fotos {
    width: 408px;
    height: 584px;
  }
}
.small {
  width: 299px;
  height: 360px;
}

.imgZoom {
  width: 100%; /* Занимает всю доступную ширину контейнера */
  height: 100%; /* Занимает всю доступную высоту контейнера */
  object-fit: cover; /* Изображение заполняет контейнер, сохраняя пропорции и обрезая лишнее */
  transition: transform 0.5s ease-in-out; /* Плавный переход при изменении масштаба */
}

.imgZoom:hover {
  transform: scale(1.2); /* Увеличение масштаба при наведении */
  transform-origin: center; /* Центрируем увеличенное изображение */
}

.shd {
  position: relative;
}

.shd::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0; /* Позиционируем псевдоэлемент внизу родительского элемента */
  width: 100%;
  height: 0; /* Начальная высота 0 */
  background-color: rgba($colorText, 0.6); /* Задаем цвет затемнения */
  transition: height 0.4s ease-in-out; /* Плавное изменение высоты */
}

.shd:hover::before {
  height: 100%; /* При наведении увеличиваем высоту до 100% */
}

.shd:hover .info__description {
  opacity: 1; /* Показываем текст при наведении */
}

.info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__couplesList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  &__description {
    @include fontH7;
    color: $colorWhite;
    transition: opacity 0.4s ease-in-out; /* Плавное появление текста */
    opacity: 0; /* Начальная прозрачность */
    text-align: center;
  }
}

.arrowIcon {
  width: 39px;
  height: 30px;
}

@media screen and (max-width: 1720px) {
  .size {
    &__pics {
      width: 469px;
      height: 600px;
    }
    &__fotos {
      width: 347px;
      height: 495px;
    }
  }
}

@media screen and (max-width: 1478px) {
  .size {
    &__pics {
      width: 363px;
      height: 520px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .size {
    &__pics {
      width: 296px;
      height: 412px;
    }
  }
}

@media screen and (max-width: 780px) {
  .size {
    // &__pics {
    //   width: 296px;
    //   height: 400px;
    // }
    &__fotos {
      width: 296px;
      height: 424px;
    }
  }
  .info {
    &__description {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: 650px) {
  .size {
    &__pics {
      width: 135px;
      height: 188px;
    }
    &__fotos {
      width: 136px;
      height: 172px;
    }
  }
  .info {
    &__description {
      font-size: 14px;
    }
  }
  .arrowIcon {
    width: 19px;
    height: 15px;
  }
}

@media screen and (max-width: 480px) {
  .size {
    &__fotos {
      width: 136px;
      height: 172px;
    }
  }
}
