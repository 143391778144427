@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.navBar {
  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-weight: bold;
    gap: 36px;
    @include subtitle_2;
    cursor: pointer;
    // text-transform: uppercase;

    a {
      text-decoration: none;
      color: $colorLight;
      position: relative; // Позиционирование для псевдоэлемента
    }

    .activeLink {
      color: $colorText;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px; // Толщина подчеркивания
        bottom: 0;
        left: 0;
        background-color: $colorText; // Цвет подчеркивания
        visibility: visible;
        transform: scaleX(1);
      }
    }

    .link {
      color: $colorLight;
      cursor: pointer;
      text-decoration: none;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: $colorLight;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }

      &:hover::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }
}

.navBar__burger {
  display: none;
}

.navBar__button {
  all: unset;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}


@media screen and (max-width: 1600px) {
  .navBar {
    &__links {
      // gap: 20px;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1345px) {
  .navBar {
    &__links {
      gap: 20px;
    }
  }
}

@media screen and (max-width: 1230px) {
  .navBar {
    &__links {
      gap: 16px;
    }
  }
}

@media screen and (max-width: 996px) {
  .navBar {
    &__links {
      gap: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: 820px) {
  .navBar__links {
    display: none;
  }

  .navBar__burger {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .navBar__button svg{
    width: 50%;
    height: 50%;
  }
}