@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;

  .popupContent {
    position: relative;
    background: transparent;
    padding: 20px;
    border-radius: 5px;
    max-width: 1200px;
    width: 100%;
    text-align: center;

    .close {
      position: absolute;
      top: 25px;
      right: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1;
      width: 30px;
    }

    .imageContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .orderImage {
        max-width: 100%;
        display: block;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.1);
        color: $colorLight;
        padding: 20px;
        box-sizing: border-box;

        h2 {
          @include fontH5;
          color: $colorLight;
          width: 900px;
          font-size: 28px;
        }
        .up {
          margin-bottom: 64px;
        }
        .down {
          margin-top: 44px;
        }

        button {
          @include subtitle_2;
          color: $colorWhite;
          padding: 16px 219px;
          background: rgba($colorText, 0.3);
          border: 3px solid $colorText;
          cursor: pointer;
        }

        p {
          @include body2;
          margin-top: 30px;
          text-align: center;
          width: 900px;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .popup {
    .popupContent {
      max-width: 1000px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .popup {
    .popupContent {
      max-width: 800px;
      .close {
        width: 25px;
      }
      .imageContainer {
        .orderImage {
          width: 800px;
          height: 725px;
        }
        .overlay {
          h2 {
            font-size: 24px;
          }
          button {
            font-size: 20px;
            padding: 14px 150px;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .popup {
    .popupContent {
      max-width: 600px;
      .imageContainer {
        .orderImage {
          width: 600px;
          height: 653px;
        }
        .overlay {
          h2 {
            width: 320px;
            font-size: 24px;
          }
          .up {
            margin-bottom: 32px;
          }
          .down {
            margin-top: 32px;
          }
          button {
            font-size: 16px;
            padding: 12px 100px;
          }
          p {
            width: 340px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .popup {
    .popupContent {
      max-width: 260px;
      .close {
        width: 20px;
      }
      .imageContainer {
        .orderImage {
          width: 260px;
          height: 670px;
        }
        .overlay {
          h2 {
            width: 200px;
            font-size: 20px;
          }
          .up {
            margin-bottom: 32px;
          }
          .down {
            margin-top: 32px;
          }
          button {
            font-size: 14px;
            padding: 5px 50px;
          }
          p {
            width: 200px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
