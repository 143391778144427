@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.team {
  background-color: $colorGrey;
  font-size: 0;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 104px 0 155px 0;
}

.team h3 {
  @include fontH2;
  color: $colorText;
  max-width: 330px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto auto;
}

.team h3 .right {
  justify-self: flex-end;
}

.our_team {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  margin-top: 117px;
  padding: 0 60px;
}

.our_team img {
  max-width: 100%;
  height: auto;
}

.team_aglaya,
.team_nastya,
.team_vika {
  display: flex;
  flex-direction: column;
}

.team_nastya {
  margin-top: 379px;
}
.team_vika {
  align-items: end;
}

.aglaya_image,
.vika_image {
  display: flex;
  position: relative;
}

.vika_image .small {
  position: absolute;
  top: 125px;
  left: -260px;
}

.aglaya_image .small {
  position: absolute;
  top: -65px;
  right: -163px;
}

.aglaya_info,
.nastya_info,
.vika_info {
  position: relative;
}

.nastya_info {
  padding-left: 40px;
}

.vika_info {
  text-align: right;
  padding-right: 35px;
}

.aglaya_info_name,
.nastya_info_name,
.vika_info_name {
  @include fontH5();
  color: $colorText;
  position: absolute;
  top: -24px;
  left: -31px;
}

.vika_info_name {
  left: 385px;
}

.aglaya_info_title,
.nastya_info_title,
.vika_info_title {
  @include fontH7;
  color: $colorText;
  margin-bottom: 24px;
  margin-top: 38px;
  max-width: 492px;
}

.nastya_info_title {
  max-width: 465px;
}

.aglaya_info_description,
.nastya_info_description,
.vika_info_description {
  @include body2;
  color: $colorText;
  margin-top: 24px;
  max-width: 448px;
  letter-spacing: 0.03em;
}

@media screen and (max-width: 1660px) {
  .container {
    padding: 85px 0;
  }
  .our_team {
    gap: 24px;
  }
  .team h3 {
    font-size: 44px;
    max-width: 330px;
  }
  .vika_image .small {
    width: 60%;
    top: 290px;
    left: -43%;
  }
  .aglaya_image .small {
    width: 50%;
    top: -20px;
    right: -28%;
  }
  .nastya_info {
    padding-left: 0;
  }
  .aglaya_info_name,
  .nastya_info_name,
  .vika_info_name {
  font-size: 32px;
  top: -24px;
  left: -31px;
  }
  .vika_info_name {
    left: 78%;
  }
  .vika_info {
    padding-right: 0;
  }
  .aglaya_info_title,
  .nastya_info_title,
  .vika_info_title {
  font-size: 28px;
  margin-top: 24px;
  }
  .aglaya_info_description,
  .nastya_info_description,
  .vika_info_description {
    font-size: 20px;
    max-width: 400px;
    line-height: 150%;
  }
  .vika_info_description {
    text-align: left;
  }
}

@media screen and (max-width: 1330px) {
  .container {
    padding: 100px 0;
  }
  .our_team {
    gap: 18px;
  }
  .team h3 {
    font-size: 36px;
    max-width: 245px;
  }
  .vika_image .small {
    top: 200px;
  }
  .team_nastya {
    margin-top: 330px;
  }
  .aglaya_info_name,
  .nastya_info_name,
  .vika_info_name {
  font-size: 24px;
  top: -15px;
  left: -24px;
  }
  .vika_info_name {
    left: 78%;
  }
  .aglaya_info_title,
  .nastya_info_title,
  .vika_info_title {
  font-size: 20px;
  margin-top: 24px;
  }
  .aglaya_info_description,
  .nastya_info_description,
  .vika_info_description {
    font-size: 16px;
    max-width: 300px;
  }
  .vika_info_description {
    text-align: right;
  }
}

@media screen and (max-width: 1110px) {
  .container {
    padding: 40px 0;
  }
  .our_team {
    gap: 12px;
    margin-top: 48px;
  }
  .team h3 {
    font-size: 32px;
    max-width: 215px;
  }
  .team_aglaya {
    margin-top: 110px;
  }
  .team_nastya {
    margin-top: 280px;
  }
  .aglaya_info_name,
  .nastya_info_name,
  .vika_info_name {
  font-size: 24px;
  top: -15px;
  left: -8px;
  }
  .vika_info_name {
    left: 70%;
  }
  .aglaya_info_description,
  .nastya_info_description,
  .vika_info_description {
    max-width: 240px;
  }
}

@media screen and (max-width: 850px) {
  .container {
    padding: 85px 0;
  }
  .our_team {
    gap: 12px;
    margin-top: 90px;
    padding: 0 15px;
  }
  .team h3 {
    font-size: 28px;
    max-width: 195px;
  }
  .team_aglaya {
    margin-top: 0;
  }
  .team_nastya {
    margin-top: 70px;
  }
  .vika_image .small {
    content: url('../../../assets/images/OurTeam/TEAM_mob.png');
    top: 102%;
    left: -41%;
  }
  .aglaya_info_name,
  .nastya_info_name,
  .vika_info_name {
  font-size: 20px;
  }
  .vika_info_name {
    left: 66%;
  }
  .aglaya_info_description,
  .nastya_info_description,
  .vika_info_description {
    max-width: 200px;
  }
  .aglaya_info_title,
  .nastya_info_title,
  .vika_info_title {
  font-size: 14px;
  max-width: 185px;
  }
}

@media screen and (max-width: 670px) {
  .container {
    padding: 35px 0;
  }
  .our_team {
    flex-wrap: wrap;
    gap: 70px;
    margin-top: 40px;
    padding: 0 25px;
  }
  .team h3 {
    font-size: 36px;
    max-width: 235px;
  }
  .team_nastya {
    order: 1;
    margin-top: 0;
  }
  .team_aglaya {
    order: 2;
  }
  .team_vika {
    order: 3;
  }
  .aglaya_image .small {
    width: 45%;
    top: -50px;
    right: -5%;
  }
  .vika_image .small {
    top: 92%;
    left: 45%;
  }
  .team_vika {
    align-items: start;
  }
  .vika_info {
    text-align: left;
  }
  .vika_info_description {
    text-align: left;
  }
  .aglaya_info_name,
  .nastya_info_name,
  .vika_info_name {
  font-size: 36px;
  top: -25px;
  }
  .vika_info_name {
    left: -15px;
  }
  .aglaya_info_description,
  .nastya_info_description,
  .vika_info_description {
    font-size: 20px;
    max-width: 450px;
    line-height: 160%;
  }
  .aglaya_info_title,
  .nastya_info_title,
  .vika_info_title {
  font-size: 28px;
  max-width: 385px;
  }
}

@media screen and (max-width: 470px) {
  .container {
    padding: 35px 0;
  }
  .our_team {
    gap: 20px;
    margin-top: 30px;
    padding: 0 16px;
  }
  .team h3 {
    font-size: 20px;
    max-width: 135px;
  }
  .aglaya_image .small {
    display: none;
  }
  .vika_image .small {
    display: none;
  }
  .aglaya_info_name,
  .nastya_info_name,
  .vika_info_name {
  font-size: 20px;
  top: -10px;
  }
  .vika_info_name {
    left: -10px;
  }
  .aglaya_info_description,
  .nastya_info_description,
  .vika_info_description {
    font-size: 16px;
    max-width: 380px;
  }
  .aglaya_info_title,
  .nastya_info_title,
  .vika_info_title {
  font-size: 14px;
  max-width: 320px;
  }
}
