@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.container {
  // max-width: 1280px;
  // display: flex;
  // justify-content: center;
  // padding: 100px 79px;
  // box-sizing: border-box;
  // gap: 58px;
  background-color: $colorGrey;
}

.instagram {
  max-width: 1920px;
  margin: 0 auto;
  padding: 104px 0 156px 0;
  // box-sizing: border-box;
  // display: flex;
  // flex-direction: column;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 0 20px;
  // justify-content: space-between; /* Равномерно распределяет элементы по строке */
  // box-sizing: border-box;

  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // gap: 58px;
}

.card {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.slideInUp {
  animation: slideInUp 1s ease forwards; /* Время анимации - 1 секунда */
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.text {
  // max-width: 520px;
  // box-sizing: border-box;
  color: $colorText;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;
  flex: none;

  .title {
    @include fontH2;
    box-sizing: border-box;

    .down {
      padding-left: 75px;
    }
  }

  .mail {
    @include fontH4;
    box-sizing: border-box;

    .highlight {
      @include fontH4;
      // color: $colorBold;
      box-sizing: border-box;
      transition: font-weight 0.5s ease;
    }

    .highlight:hover {
      font-weight: bold;
    }
  }

  .descrcription {
    @include body1;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 1720px) {
  .instagram {
    padding: 148px 0 113px 0;
  }
  .text {
    gap: 36px;
    .title {
      font-size: 44px;
      .down {
        padding-left: 69px;
      }
    }
    .mail {
      .highlight {
        font-size: 36px;
      }
    }
    .descrcription {
      font-size: 24px;
      margin-bottom: 52px;
    }
  }
}

@media screen and (max-width: 1478px) {
  .instagram {
    padding: 90px 0 113px 0;
  }
  .text {
    .title {
      font-size: 36px;
      .down {
        padding-left: 57px;
      }
    }
    .mail {
      .highlight {
        font-size: 32px;
      }
    }
    .descrcription {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1160px) {
  .instagram {
    padding: 64px 0 80px 0;
  }
  .text {
    gap: 36px;
    .title {
      font-size: 32px;
      .down {
        padding-left: 50px;
      }
    }
    .mail {
      .highlight {
        font-size: 28px;
      }
    }
    .descrcription {
      font-size: 20px;
      margin-bottom: 36px;
    }
  }
}

@media screen and (max-width: 780px) {
  .instagram {
    padding: 49px 0 59px 0;
  }
  .text {
    gap: 16px;
    .mail {
      .highlight {
        font-size: 20px;
      }
    }
    .descrcription {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 650px) {
  .instagram {
    max-width: 450px;
    padding: 40px 0 41px 0;
  }
  .text {
    gap: 12px;
    padding: 0 20% 0 20%;
    .title {
      font-size: 20px;
      .down {
        padding-left: 31px;
      }
    }
    .mail {
      .highlight {
        font-size: 16px;
      }
    }
    .descrcription {
      font-size: 12px;
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 480px) {
  .cards {
    padding: 0 16px;
  }
}
