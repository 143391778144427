@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.policy {
  padding: 257px 0 263px 0;
  background-color: $colorLight;
  /*font-size: 0;*/
  box-sizing: border-box;
  border: none;
}

.policy img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.policy h2 {
  @include fontH2;
  color: $colorGrey;
  max-width: 992px;
  margin: 0 auto;
  margin-top: 44px;
  margin-bottom: 116px;
  display: grid;
  grid-template-rows: auto auto;
}

.policy h2 .right {
  justify-self: flex-end;
}

.policy__main {
  @include body1;
  padding: 0 184px;
  color: $colorGrey;
  text-align: justify;
}

.policy__general {
  margin-bottom: 55px;
}

@media screen and (max-width: 1670px) {
  .policy__main {
    font-size: 24px;
    padding: 0 5%;
  }
  .policy h2 {
    font-size: 44px;
    max-width: 56%;
    margin-bottom: 36px;
  }
  .policy__general {
    margin-bottom: 3%;
  }
}

@media screen and (max-width: 1300px) {
  .policy img {
    width: 106px;
  }
  .policy__main {
    font-size: 20px;
  }
  .policy h2 {
    font-size: 36px;
    margin-top: 88px;
    margin-bottom: 36px;
  }
  .policy__general {
    margin-bottom: 36px;
  }
}

@media screen and (max-width: 997px) {
  .back {
    padding-left: 80px;
    width: 100px;
  }
  .policy img {
    width: 100px;
  }
  .policy {
    padding: 159px 0 213px 0;
  }
  .policy__main {
    font-size: 16px;
  }
  .policy h2 {
    font-size: 28px;
    margin-top: 37px;
    margin-bottom: 36px;
  }
  .policy__general {
    margin-bottom: 28px;
  }
}

@media screen and (max-width: 680px) {
  .policy h2 {
    font-size: 24px;
    // max-width: fit-content;
  }
}

@media screen and (max-width: 580px) {
  .policy img {
    width: 84px;
  }
  .policy {
    padding: 159px 0 213px 0;
  }
  .policy h2 {
    font-size: 20px;
    max-width: 66%;
    margin-top: 35px;
    margin-bottom: 32px;
  }
  .policy__main {
    line-height: 130%;
  }
  .policy__general {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 480px) {
  .back {
    padding-left: 20px;
    width: 84px;
  }
  .policy {
    padding: 100px 0 153px 0;
  }
  .policy h2 {
    font-size: 20px;
    max-width: 90%;
  }
  .policy__main {
    text-align: left;
  }
}
