@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.order {
    background-color: #5F5B5A;
    font-size: 0;
}

.container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 156px 0 306px 0;   
}

.picture {
    text-align: center;
    padding: 0 15px;

    img {
        max-width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 1100px) {
    .container {
        padding: 126px 0 256px 0;   
    }
}

@media screen and (max-width: 870px) {
    .container {
        padding: 86px 0 206px 0;   
    }
}

@media screen and (max-width: 650px) {
    .container {
        padding: 66px 0 126px 0;   
    }
}

@media screen and (max-width: 560px) {
    .container {
        padding: 56px 0 66px 0;   
    }
}

@media screen and (max-width: 400px) {
    .container {
        padding: 26px 0 26px 0;   
    }
}