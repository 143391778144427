@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;

  .popupContent {
    position: relative;
    background: transparent;
    padding: 20px;
    border-radius: 5px;
    max-width: 1200px;
    width: 100%;
    text-align: center;

    .close {
      position: absolute;
      top: 25px;
      right: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1;
      width: 30px;
    }

    .imageContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .orderImage {
        max-width: 100%;
        display: block;
        object-fit: cover;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgba(0, 0, 0, 0.1);
        color: $colorLight;
        padding: 20px;
        box-sizing: border-box;

        .description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 42px;
          width: 900px;
        }

        h2 {
          @include fontH5;
          margin-top: 24px;
        }

        h3 {
          @include body1;
          margin-top: 50px;
          margin-bottom: 8px;
          text-align: center;
        }

        p {
          @include body1;
          margin-bottom: 30px;
          text-align: center;

          a {
            color: $colorLight;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .required {
          @include body1;
          margin-top: 30px;
          text-align: center;
        }

        .number {
          @include body1;
          margin-top: 120px;
          margin-left: 30px;
          align-self: flex-start;
        }

        .form {
          width: 100%;

          .row {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            gap: 10px;

            label {
              flex: 1;
              position: relative;
              margin: 0 5px;
              display: flex;
              flex-direction: column;

              input {
                @include fontH6;
                color: $colorText;
                background: none;
                border: none;
                border-bottom: 1px solid $colorText;
                width: 96%;
                padding: 8px;
                margin-top: 5px;
                border-radius: 0;
                &::placeholder {
                  color: rgba($colorText, 0.7);
                }
              }

              .tooltipError {
                @include body1;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: rgba(255, 0, 0, 0.9);
                color: $colorWhite;
                padding: 5px;
                border-radius: 4px;
                font-size: 14px;
                white-space: nowrap;
                z-index: 10;
                pointer-events: none; // Для того, чтобы ошибка не мешала взаимодействию с инпутом
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.3s ease, visibility 0.3s ease;
              }

              .visible {
                opacity: 1;
                visibility: visible;
              }

              .inputError {
                border-bottom: 1px solid red;
              }
            }
          }

          button {
            @include subtitle_2;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            background-color: rgba($colorText, 0.3); // Стиль для неактивной кнопки
            color: $colorLight;
            cursor: not-allowed; // Курсор для неактивной кнопки
            width: 400px;
            margin-top: 50px;
            
            &:hover {
              background-color: rgba($colorText, 0.3); // Не меняем цвет при наведении, если кнопка неактивна
            }
          
            &.active {
              background-color: rgba($colorText, 0.7); // Стиль для активной кнопки
              cursor: pointer; // Курсор меняется на указатель для активной кнопки
            }
          
            &.active:hover {
              background-color: rgba($colorText, 0.7); // Для активной кнопки оставляем цвет без изменений при наведении
            }
          }
        }

        .successMessage {
          color: $colorText;
        }

        .errorMessage {
          color: red;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .popup {
    .popupContent {
      max-width: 1000px;
      .imageContainer {
        .overlay {
          .description {
            width: 750px;
          }
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 20px;
          }
          .required {
            font-size: 20px;
          }
          .number {
            margin-top: 100px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .popup {
    .popupContent {
      max-width: 800px;
      .close {
        width: 25px;
      }
      .imageContainer {
        .orderImage {
          width: 800px;
          height: 725px;
        }
        .overlay {
          .description {
            width: 700px;
          }
          h2 {
            margin-top: 40px;
            font-size: 24px;
          }
          .form {
            .row {
              label {
                input {
                  font-size: 20px;
                }
              }
            }
            button {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .popup {
    .popupContent {
      max-width: 600px;
      .imageContainer {
        .orderImage {
          width: 600px;
          height: 653px;
        }
        .overlay {
          .description {
            width: 500px;
          }
          h2 {
            font-size: 20px;
          }
          h3 {
            margin-top: 60px;
            margin-bottom: 13px;
            font-size: 20px;
          }
          .form {
            .row {
              margin-bottom: 30px;
              label {
                input {
                  font-size: 16px;
                }
                .tooltipError {
                  @include body1;
                  font-size: 12px;
                }
              }
            }
            button {
              font-size: 18px;
              padding: 12px 100px;
            }
          }
          p {
            font-size: 16px;
          }
          .required {
            font-size: 16px;
          }
          .number {
            margin-top: 60px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .popup {
    .popupContent {
      max-width: 260px;
      .close {
        width: 20px;
      }
      .imageContainer {
        .orderImage {
          width: 260px;
          height: 670px;
        }
        .overlay {
          .description {
            gap: 30px;
            width: 220px;
          }
          h2 {
            margin-top: 10px;
            font-size: 14px;
          }
          h3 {
            margin-top: 44px;
            margin-bottom: 13px;
            font-size: 10px;
          }
          .form {
            .row {
              flex-direction: column;
              gap: 10px;
              margin-bottom: 10px;
              label {
                input {
                  font-size: 16px;
                }
                .tooltipError {
                  @include body1;
                  font-size: 10px;
                }
              }
            }
            button {
              font-size: 14px;
              padding: 10px 40px;
              width: auto;
              margin: 0 auto;
            }
          }
          p {
            font-size: 12px;
          }
          .required {
            font-size: 12px;
            width: 156px;
            margin: 39px auto 32px;
          }
          .number {
            margin-top: 30px;
            margin-left: 21px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
