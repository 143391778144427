@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.content {
  max-width: 100%;
  &__section {
    display: flex;
  }
}

* {
  margin: 0;
  padding: 0;
}

.coordination {
  padding: 257px 0 336px 0;
  background-color: $colorGrey;
  font-size: 0;
  box-sizing: border-box;
  border: none;
}

.coordination img {
  max-width: 100%;
  /*height: auto;*/
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

.back {
  padding-left: 120px;
}

.coordination h2 {
  @include fontH2;
  color: $colorText;
  max-width: 488px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 84px;
  display: grid;
  grid-template-rows: auto auto;
}

.coordination h2 .right {
  justify-self: flex-end;
}

.hand {
  animation: advantages-hand 4s infinite
  }
  @keyframes advantages-hand {
  0% { transform: translateX(0) scale(3); opacity: 0 }
  33% { transform: translateX(0) scale(1); opacity: 1 }
  66% { transform: translateX(0) scale(1); opacity: 1 }
  100% { transform: translateX(0) scale(3); opacity: 0 }
  }

.hand {
  text-align: end;
  // padding-right: 170px;
  // margin-bottom: 150px;
}

.coordination__images {
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 0 20px;
  margin-bottom: 338px;
}

.coordination__img1 {
  transform: translate(10px);
}

.coordination__img2 {
  transform: translate(-10px);
  padding-bottom: 93px;
}

.package__price {
  padding: 92px 12%;
  border-bottom: 1px solid $colorText;
  border-top: 1px solid $colorText;
}

.package__visible {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.package__items {
  padding: 92px 12%;
  border-bottom: 1px solid $colorText;
  margin-bottom: 200px;
}

.package__action {
  display: flex;
  justify-content: center;
  padding: 0 15px;
}

.package__button {
  @include subtitle_1;
  padding: 16px 20%;
  border: 3px solid $colorText;
  color: $colorWhite;
  background-color: rgb($colorText, 0.3);
  text-decoration: none;
  max-width: 995px;
  text-align: center;
}

.package__sum {
  @include body1;
  color: $colorWhite;
  margin-top: 84px;
}

.package__list {
  @include body1;
  color: $colorWhite;
  margin-top: 84px;
  max-width: 830px;
}

.package__string {
  margin-top: 48px;
}

@media screen and (max-width:1690px) {
  .coordination h2 {
    font-size: 44px;
    max-width: 447px;
    margin-top: 30px;
    margin-bottom: 84px;
  }
  .coordination__images {
    padding: 0 70px;
  }
  .package img {
    max-width: 90%;
  }
}

@media screen and (max-width:1300px) {
  // .coordination {
  //   padding: 213px 0 236px 0;
  // }
  .back {
    padding-left: 120px;
    width: 110px;
  }
  .package img {
    max-width: 80%;
  }
  .package__price, .package__items {
    padding: 92px 8%;
  }
  .package__sum, .package__list {
    font-size: 24px;
  }
}

@media screen and (max-width:1050px) {
  .coordination {
    padding: 213px 0 236px 0;
  }
  .back {
    padding-left: 120px;
    width: 110px;
  }
  .coordination h2 {
    font-size: 36px;
    max-width: 370px;
    margin-top: 30px;
    margin-bottom: 84px;
  }
  .coordination__images {
    padding: 0 20px;
    margin-bottom: 238px;
  }
  .package img {
    max-width: 70%;
  }
  .hand {
    animation: advantages-hand 4s infinite
    }
    @keyframes advantages-hand {
    0% { transform: translateX(0) scale(2); opacity: 0 }
    33% { transform: translateX(0) scale(0.8); opacity: 1 }
    66% { transform: translateX(0) scale(0.8); opacity: 1 }
    100% { transform: translateX(0) scale(2); opacity: 0 }
    }
  .package__price, .package__items {
    padding: 82px 8%;
  }
  .package__sum, .package__list {
    font-size: 20px;
  }
  .package__string {
    margin-top: 36px;
  }
  .cross svg {
    width: 80%;
    height: 80%;
  }
  .package__items {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 900px) {
  .package img {
    max-width: 90%;
  }
  .package__info {
    width: 500px;
  }
  .coordination {
    padding: 159px 0 186px 0;
  }
  .back {
    padding-left: 100px;
    width: 85px;
  }
  .coordination h2 {
    font-size: 28px;
    max-width: 285px;
    margin-top: 80px;
    margin-bottom: 70px;
  }
  .coordination__images {
    padding: 0 20px;
    margin-bottom: 108px;
  }
  .package__items {
    margin-bottom: 50px;
  }
  .package__price, .package__items {
    border: none;
  }
  .coordination__img2 {
    padding-bottom: 43px;
  }
  .hand {
    animation: advantages-hand 4s infinite
    }
    @keyframes advantages-hand {
    0% { transform: translateX(0) scale(1.5); opacity: 0 }
    33% { transform: translateX(0) scale(0.8); opacity: 1 }
    66% { transform: translateX(0) scale(0.8); opacity: 1 }
    100% { transform: translateX(0) scale(1.5); opacity: 0 }
    }
  .package__price, .package__items {
    padding: 62px 5%;
  }
  .package__sum, .package__list {
    font-size: 18px;
  }
  .package__string {
    margin-top: 26px;
  }
  .cross svg {
    width: 70%;
    height: 70%;
  }
  .package__items {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 612px) {
  .package img {
    max-width: 90%;
  }
  .package__info {
    width: 400px;
  }
  .coordination {
    padding: 137px 0 126px 0;
  }
  .back {
    padding-left: 20px;
    width: 85px;
  }
  .coordination h2 {
    font-size: 20px;
    max-width: 204px;
    margin-top: 36px;
    margin-bottom: 70px;
  }
  .coordination__images {
    padding: 0 20px;
    margin-bottom: 58px;
  }
  .package__items {
    margin-bottom: 50px;
  }
  .coordination__img2 {
    display: none;
  }
  .hand {
    animation: advantages-hand 4s infinite
    }
    @keyframes advantages-hand {
    0% { transform: translateX(0) scale(1); opacity: 0 }
    33% { transform: translateX(0) scale(0.5); opacity: 1 }
    66% { transform: translateX(0) scale(0.5); opacity: 1 }
    100% { transform: translateX(0) scale(1); opacity: 0 }
    }
  .package__price, .package__items {
    padding: 32px 5%;
  }
  .package__sum, .package__list {
    padding-left: 40px;
    margin-top: 30px;
  }
  .package__string {
    margin-top: 26px;
  }
  .cross svg {
    width: 70%;
    height: 70%;
  }
  .package__items {
    margin-bottom: 50px;
  }
  .package__button {
    font-size: 16px;
    padding: 10px 15%;
    border: 2px solid $colorText;
  }
}

@media screen and (max-width: 501px) {
  .package__visible {
    justify-content: end;
  }
  .package__info {
    padding-right: 50px;
  }
  .package__sum, .package__list {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .coordination {
    padding: 97px 0 126px 0;
  }
  .coordination__images {
    padding: 0 10px;
    margin-bottom: 38px;
  }
  .package img {
    max-width: 110%;
  }
  .coordination h2 {
    margin-top: 36px;
    margin-bottom: 30px;
  }
  .package__price, .package__items {
    padding: 10px 15px;
  }
  .package__button {
    padding: 8px 9%;
  }
  .package__items {
    margin-bottom: 30px;
  }
  .package__string {
    margin-top: 20px;
  }
}