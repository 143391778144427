@use '../../assets/styles/styles.scss' as *;
@import '../../assets/styles/variables.scss';

.footer {
  background-color: $colorGrey;
  font-size: 0;
  position: sticky;
  top: 0;
  z-index: 100;
  position: relative;
}
img {
  max-width: 100%;
  height: auto;
}

.footerContent {
  max-width: 1740px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px 19px 20px;
  &__link {
    @include subtitle_2;
    text-decoration: none;
    color: $colorText;
    position: relative; // Позиционирование для псевдоэлемента
  }
  &__logo {
    color: $colorWhite;
    padding-right: 20px;
  }
  &__business_card {
    position: relative;
    top: -135px; // Выход картинки за пределы футера
  }
  &__columns {
    display: flex;
    justify-content: space-between;
  }
  &__info {
    width: 750px;
    display: flex;
    justify-content: space-between;
    // flex-direction: row;
    padding-top: 97px;
    align-items: center;
    // gap: 60px;
  }
  &__menu {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: flex-start;
    gap: 12px;
  }
  &__socialMedia {
    display: flex;
    align-self: start;
    gap: 8px;
  }
  &__bottom {
    @include subtitle_4;
    color: $colorText;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.link {
  text-decoration: none;
  color: $colorText;
  margin-top: 10px;
  position: relative; // Позиционирование для псевдоэлемента

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; // Толщина подчеркивания
    bottom: 0;
    left: 0;
    background-color: $colorText; // Цвет подчеркивания
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  &:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }

  &__politics {
    margin-top: 10px;
    @include subtitle_4;
  }
  &__tr {
    @include subtitle_2;
    text-transform: none;
  }
}

@media screen and (max-width: 1550px) {
  .footerContent__bottom {
    margin-top: 74px;
  }
}

@media screen and (max-width: 1210px) {
  .footerContent__cards {
    margin-left: 20px;
  }
  .footerContent__info {
    padding-top: 55px;
  }
  .footerContent__logo {
    padding-right: 40px;
  }
}

@media screen and (max-width: 960px) {
  .footerContent__cards {
    margin-left: 30px;
  }
  .footerContent__info {
    padding-top: 15px;
  }
  .footerContent__menu {
    gap: 8px;
    font-size: 16px;
    margin-right: 60px;
  }
  .footerContent__bottom {
    margin-top: 32px;
  }
}

@media screen and (max-width: 806px) {
  .footerContent {
    padding: 0 30px 19px 30px;
  }
  .footerContent__business_card {
    width: 300px;
    top: -90px; // Выход картинки за пределы футера
  }
  .footerContent__cards {
    margin-left: 20px;
  }
  .footerContent__info {
    padding-top: 0;
  }
  .footerContent__menu {
    gap: 8px;
    font-size: 16px;
    margin-right: 30px;
  }
  .footerContent__logo {
    padding-right: 20px;
  }
}

@media screen and (max-width: 620px) {
  .footerContent {
    padding: 0 16px 11px 11px;
  }
  .footerContent__business_card {
    width: fit-content;
    top: 0; // Выход картинки за пределы футера
  }
  .footerContent__bottom,
  .link__politics {
    font-size: 12px;
    max-width: 272px;
    text-align: center;
    margin: 0 auto;
  }
  .footerContent__cards {
    max-width: 100%;
    margin-left: 0;
  }
  .footerContent__info {
    padding-top: 0;
  }
  .footerContent__columns {
    position: relative;
    gap: 50px;
    margin-bottom: 57px;
  }
  .footerContent__menu {
    display: none;
  }
  .footerContent__socialMedia {
    position: absolute;
    left: 50%;
    bottom: -35px;
    transform: translateX(-50%);
  }
  .footerContent__logo {
    max-width: 100%;
    padding-right: 0;
  }
}
