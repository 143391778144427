@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.popup {
  position: fixed;
  top: 20px;
  right: 0;
  width: 82%;
  background-color: #5f5b5a;
  z-index: 1000;
  max-height: 100vh;
  overflow-y: auto;

  .popupContent {
    position: relative;
    // background: transparent;
    padding: 30px 0 21px 20%;
    display: flex;
    justify-content: left;
    align-items: center;

    .close {
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1;
    }

    .infoContainer {
      font-family: 'Helvetica';
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.03em;
      color: #d9d0cd;

      .navBar {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 28px;
      }

      .navBar a {
        @include subtitle_2;
        color: #d9d0cd;
      }

      .telephone {
        margin-bottom: 16px;
      }
      .socialMedia {
        display: flex;
        justify-content: left;
        gap: 5px;
        margin-bottom: 16px;
      }
      .button {
        box-sizing: border-box;
        padding: 10px 15px;
        border: 1px solid #d9d0cd;
        color: #d9d0cd;
        background-color: transparent;
        border-radius: 12px;
        max-width: 115px;
      }
    }
  }
}

// @media screen and (min-width: 520px) and (max-width: 810px) {
//   .infoContainer {
//     font-size: 20px;
//   }
// }
