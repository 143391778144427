@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.guide {
  font-size: 0;
  background-image: url('../../../assets/images/Guide/guide.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 380px 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 116px
}

.guide_text {
  @include fontH5;
  color: $colorText;
  width: 770px;
  display: grid;
  grid-template-rows: auto auto;
  margin-bottom: 30px;
}

.guide_text .right {
  justify-self: flex-end;
}

.guide_action button {
  padding: 16px 219px;
  background: rgba(210, 189, 180, 0.3);
  border: 3px solid $colorText;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: $colorWhite;
  cursor: pointer;
}

@media screen and (max-width:1670px) {
  .container {
    padding: 380px 20px;
    justify-content: space-around;
    gap: 0;
  }
  .guide_text {
    font-size: 32px;
    width: 684px;
    margin-bottom: 0;
  }
  .guide_action button {
    padding: 16px 219px;
    font-size: 20px;
  }
}

@media screen and (max-width: 1390px) {
  .container {
    padding: 280px 20px;
  }
  .guide_text {
    font-size: 24px;
    width: 513px;
  }
  .guide_action button {
    padding: 16px 161px;
  }
}

@media screen and (max-width: 1090px) {
  .container {
    padding: 218px 15px 180px 15px;
  }
  .guide_text {
    font-size: 20px;
    width: 427px;
  }
  .guide_action button {
    padding: 16px 110px;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 96px 20px 137px 20px;
    gap: 64px;
  }
  .guide_text {
    font-size: 32px;
    width: 680px;
  }
  .guide_action button {
    padding: 16px 270px;
  }
}

@media screen and (max-width: 780px) {
  .container {
    padding: 121px 20px;
    gap: 64px;
  }
  .guide_text {
    font-size: 28px;
    width: 625px;
  }
  .guide_action button {
    font-size: 16px;
    padding: 16px 244px;
  }
}

@media screen and (max-width: 780px) {
  .guide_action button {
    padding: 16px 184px;
  }
}

@media screen and (max-width: 553px) {
  .guide_action button {
    padding: 16px 124px;
  }
}

@media screen and (max-width: 553px) {
  .container {
    padding: 77px 20px 110px 20px;
    gap: 32px;
  }
  .guide_text {
    font-size: 16px;
    width: 288px;
  }
  .guide_action button {
    font-size: 14px;
    padding: 8px 75px;
  }
}
