@use '../../../assets/styles/styles.scss' as *;
@import '../../../assets/styles/variables.scss';

.button {
  @include subtitle_2;
  color: $colorLight;
  width: 150px;
  background-color: transparent;
  padding: 13px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-sizing: border-box;
  border: 1px solid #D9D0CD;
  //   border-radius: 5px;
}

@media screen and (max-width: 1600px) {
  .button {
    width: fit-content;
    // padding: 13px 20px;
  }
}

@media screen and (max-width: 1290px) {
  .button {
    font-size: 16px;
    padding: 8px 20px;
  }
}
